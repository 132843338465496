export const documentModel = () => ({
  code: "",
  description: "",
  name: "",
});

export const documentInitialState = {
  isLoading: false,
  documents: [],
  documentsFilters: {
    filters: { terms: "", status: [], companyName: "", siren: "", email: "", customerName: "" },

    page: 1,
  },
  documentsSearch: [],
  document: documentModel(),
  errors: {},
};

export default function (state = documentInitialState, action) {
  switch (action.type) {
    case "UPDATE_DOCUMENTS_FILTERS":
      return {
        ...state,
        documentsFilters: action.payload,
      };
    case "UPDATE_DOCUMENTS_SEARCH":
      return {
        ...state,
        documentsSearch: action.payload,
      };
    case "UPDATE_DOCUMENTS":
      return {
        ...state,
        documents: action.payload,
      };

    case "UPDATE_DOCUMENTS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_DOCUMENTS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_DOCUMENT":
      return {
        ...state,
        document: action.payload,
      };
    default:
      return state;
  }
}
