export const customerModel = () => ({
  firstname: "",
  lastname: "",
  role: "",
  email: "",
  password: "",
  isActivated: false,
  addresses: [],
});

export const customerInitialState = {
  isLoading: false,
  customers: [],
  customersFilters: {
    filters: { terms: "" },
    page: 1,
  },
  customersSearch: [],
  customer: customerModel(),
  errors: {},
};

export default function (state = customerInitialState, action) {
  switch (action.type) {
    case "UPDATE_CUSTOMERS_FILTERS":
      return {
        ...state,
        customersFilters: action.payload,
      };
    case "UPDATE_CUSTOMERS_SEARCH":
      return {
        ...state,
        customersSearch: action.payload,
      };
    case "UPDATE_CUSTOMERS":
      return {
        ...state,
        customers: action.payload,
      };

    case "UPDATE_CUSTOMERS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_CUSTOMERS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_CUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };
    default:
      return state;
  }
}
