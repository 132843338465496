import { generatePath } from "react-router-dom";
import dataService from "../../../../helpers/dataService";

class ShopModel {
  constructor() {
    this.searchURI = "shops/search";
    this.getEntityURI = "shops/:entityId";
    this.postEntityURI = "shops";
    this.patchEntityURI = "shops/:id";
  }

  search(filters) {
    return new Promise((resolve, reject) => {
      dataService.post(this.searchURI, filters, resolve, reject);
    });
  }
  getEntity(entityId) {
    return new Promise((resolve, reject) => {
      const path = generatePath(this.getEntityURI, { entityId });
      dataService.get(path, resolve, reject);
    });
  }
  postEntity(entityData) {
    return new Promise((resolve, reject) => {
      dataService.post(this.postEntityURI, entityData, resolve, reject);
    });
  }
  patchEntity(entityData) {
    return new Promise((resolve, reject) => {
      const path = generatePath(this.patchEntityURI, { id: entityData.id });
      dataService.patch(path, entityData, resolve, reject);
    });
  }
}

export default ShopModel;
