import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";
import useAllChests from "../../../hooks/useAllChests/useAllChests";

const AllChestsListTable = () => {
  const { isLoading, allChestsSearch, languages, allChests_actions, state } = useAllChests();

  return (
    <Table
      isLoading={isLoading}
      nbResults={allChestsSearch.count}
      datas={allChestsSearch.data}
      fields={[
        {
          name: "Société",
          path: "*",
          render: (el) => (
            <>
              <div>{el.companyName}</div>
              <small>{el.siret}</small>
            </>
          ),
        },
        {
          name: "Créé le",
          path: "createdAt",
          render: (el) => tools.formatDate(el),
        },
        {
          name: "Client",
          path: "userCustomer",
          render: (el) => {
            return (
              <>
                <Link to={`customers/${el.id}?tab=2`}>{tools.getFullname(el)}</Link>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default AllChestsListTable;
