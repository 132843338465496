import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import useAllChests from "../../../hooks/useAllChests/useAllChests";

import AllChestsFilters from "./AllChestsFilters";
import AllChestsListTable from "./AllChestsListTable";

const AllChestsList = (props) => {
  const { allChestsState, allChestsSearch, allChestsFilters, allChests_actions, state, dispatch } =
    useAllChests();

  return (
    <Page
      title="Coffres"
      errors={allChestsState.errors}
      isLoading={allChestsState.isLoading}
      {...props}
    >
      <div className="">
        <AllChestsFilters />
        <div className="mt-3">
          <AllChestsListTable />
        </div>
        {allChestsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(allChestsSearch.count / 25)}
            page={allChestsFilters.page}
            changePage={(p) =>
              allChests_actions.updatePage({
                ...allChestsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default AllChestsList;
