import { Link } from "react-router-dom";
import colors from "../../../../helpers/colors";
import UserBox from "../UserBox";

const DesktopMenu = ({
  items,
  activeLink,
  subMenu,
  subActiveLink,
  setActiveLink,
  setSubActiveLink,
  setSubMenu,
}) => {
  return (
    <nav
      id="topmenu"
      className={
        "noprint navbar navbar-expand-lg shadow-sm  text-white p-0 d-flex flex-column  w-100 shadow-sm"
      }
      style={{
        position: "fixed",
        top: 0,
        background: colors.secondary,
        //background: "rgba(29, 29, 29, 0.44)",
        //backdropFilter: "blur(7px)",
        zIndex: "99",
      }}
    >
      <div
        className="collapse navbar-collapse d-flex flex-row justify-content-center align-items-center  w-100  "
        id="navbarSupportedContent"
        style={{
          transition: "400ms",
        }}
      >
        {/* <ul className="navbar-nav me-auto">{getMenu(items)}</ul> */}
        <ul
          className="pl-0 top-menubar  mb-0 d-flex flex-wrap align-items-center"
          style={{ listStyle: "none", minHeight: 44 }}
        >
          {items.map((it, itk) => {
            var isActive = itk == activeLink;
            return (
              <li data-priv={it.dataPriv} className="nav-item" key={`menuitem${itk}`}>
                <Link
                  to={it.path}
                  data-attr={isActive ? "active" : ""}
                  className={` nav-link  btn btn-sm mx-1 text-white ${
                    it.path == "#" ? "has-children" : ""
                  }`}
                  style={{
                    fontSize: 15,
                  }}
                  onClick={() => {
                    setActiveLink(itk);
                    if (it.path == "#") {
                      setSubMenu(it.items);
                    } else {
                      setSubMenu([]);
                    }
                  }}
                >
                  <i className={`${it.icon} me-2`} /> {it.name}
                  {/* {it.path == "#" ? (
                    <i className={`fa fa-chevron-down me-1`} />
                  ) : null} */}
                </Link>
              </li>
            );
          })}

          <li
            className="nav-item"
            style={{
              position: "absolute",
              right: 25,
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: "9999",
            }}
          >
            <UserBox />
          </li>
        </ul>
      </div>
      {subMenu.length ? (
        <div className="w-100 d-center overflow-hidden ">
          <ul
            className=" px-2 bottom-menubar d-flex flex-wrap"
            style={{ listStyle: "none", marginBottom: 2 }}
          >
            {subMenu.map((it, itk) => {
              var isActive = itk == subActiveLink;
              return (
                <li
                  className="nav-item mt-2"
                  key={`submenuitem${itk}${it.path}`}
                  data-priv={it.dataPriv}
                >
                  <Link
                    to={it.path}
                    className={` nav-link mx-1 btn btn-sm text-white animated fadeInDown`}
                    style={{
                      animationDelay: itk * 30 + "ms",
                      animationDuration: "200ms",
                      fontSize: 15,
                    }}
                    onClick={() => setSubActiveLink(itk)}
                    data-attr={isActive ? "active" : ""}
                  >
                    <i className={`${it.icon} me-2`} />

                    {it.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
    </nav>
  );
};

export default DesktopMenu;
