import React, { Component, useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import { authActions } from "../../../../../context/actions/authActions";
import useStore from "../../../../../context/useStore";
import UserBox from "../../UserBox";
import "./mobilemenu.scss";

const MobileMenu = ({ items, activeLink, setActiveLink, subActiveLink, setSubActiveLink }) => {
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = (event) => {
    event.preventDefault();
    // Using the parent component's state to keep track of the menu
    setIsOpen(false);
  };

  return (
    <>
      <UserBox style={{ position: "absolute", zIndex: "9999", top: 18, right: 24 }} />
      <Menu isOpen={isOpen} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
        {items.map((item, itk) => {
          var isActive = itk == activeLink;
          return item.path != "#" ? (
            <Link
              key={`mobile-menu-item${item.path}`}
              data-attr={isActive ? "active" : ""}
              onClick={() => {
                setActiveLink(itk);
                setIsOpen(false);
              }}
              className={`menu-item ${isActive ? "bg-secondary" : ""}`}
              to={item.path}
              data-priv={item.dataPriv}
            >
              {item.path != "#" && <i className={`${item.icon} me-3`} />}
              {item.name}
            </Link>
          ) : (
            <>
              <div
                className=" w-100 menu-item bm-item text-uppercase my-3"
                style={{
                  fontWeight: "400",
                  color: "#848383",
                }}
              >
                {item.name}
              </div>
              {item.items.map((subItem, subk) => {
                var isActive = subk == subActiveLink;
                return (
                  <Link
                    data-attr={isActive ? "active" : ""}
                    key={`mobile-menu-item${subItem.path}`}
                    onClick={() => {
                      setSubActiveLink(subk);
                      setIsOpen(false);
                    }}
                    className={`menu-item  text-white d-flex  ${isActive ? "bg-secondary" : ""}`}
                    to={subItem.path}
                    data-priv={subItem.dataPriv}
                    style={{
                      fontSize: 17,
                      padding: "10px 1.8rem",
                      textDecoration: "none",
                    }}
                  >
                    <i className={`${subItem.icon} me-3`} />
                    {subItem.name}
                  </Link>
                );
              })}
            </>
          );
        })}
        <button
          className={`menu-item btn text-white mt-5 pt-4  w-100`}
          style={{
            position: "absolute",
            bottom: 6,
            left: 0,
          }}
          onClick={auth_actions.logoutUser}
        >
          {/* <i className={`fa fa-sign-out-alt me-3`} /> */}
          Déconnexion
        </button>
      </Menu>
    </>
  );
};

// let options = [
//   {
//     title: "Education",
//     titleIcon: <i className="fa fa-graduation-cap"></i>,
//     content: [{ id: 1, name: "My courses", to: "/my-courses" }],
//   },
//   {
//     title: "Most popular",
//     titleIcon: <i className="fa fa-paragraph"></i>,
//     hideBorder: true,
//     content: [
//       {
//         id: 2,
//         name: "Web Development",
//         icon: <i className="fa fa-chrome"></i>,
//         children: [
//           {
//             title: "JavaScript",
//             titleIcon: <i className="fa fa-opera"></i>,
//             content: [
//               {
//                 id: 3,
//                 name: "functions",
//                 ["Some property i need on clicking this"]: "value",
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
// ];
// options = [
//   {
//     content: [],
//   },
// ];
// menuItems.forEach((item, itemk) => {
//   options[0].content.push({
//     id: itemk,
//     name: item.name,
//     to: item.path,
//     icon: <i className={item.icon} />,
//   });
// });

// class MobileMenu extends Component {
//   constructor() {
//     super();
//     this.state = {
//       isOpen: false,
//     };
//   }

//   //   you can also use this function on any of your event to open/close the sidebar
//   handleSidebarToggle = (isOpen) => {
//     this.setState({ isOpen });
//   };

//   handleClick = (itemOptions) => {
//     /*
//         do something with the item you clicked.
//         you can also send custom properties of your choice
//         in the options array you'll be getting those here
//         whenever you click that item
//     */
//   };

//   render() {
//     return (
//       <div>
//         <MultilevelSidebar
//           open={this.state.isOpen}
//           onToggle={this.handleSidebarToggle}
//           options={options}
//           header="React-MultiLevel-Sidebar"
//           onItemClick={this.handleClick}
//         />
//         {/* using in our button to open the sidebar */}
//         <button onClick={() => this.handleSidebarToggle(true)}>open</button>
//       </div>
//     );
//   }
// }

export default MobileMenu;
