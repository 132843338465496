const colors = {
  primary: "#dc142d",
  secondary: "#0e2b67",
  tertiary: "#292c2f",
  success: "#028d33",
  info: "#009eff",
  warning: "yellow",
  dark: "black",
  danger: "red",
  light: "#fcfcfc",
  default: "#ededed",
  trans: "rgba(0, 0, 0, 0)",
  input: "#a8a8a8",
  content: "#1d1d1d",
  white: "#ffffff",
};

export default colors;
