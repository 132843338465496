import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import useSubscriptionTemplates from "../../../hooks/useSubscriptionTemplates/useSubscriptionTemplates";

import SubscriptionTemplatesFilters from "./SubscriptionTemplatesFilters";
import SubscriptionTemplatesListTable from "./SubscriptionTemplatesListTable";

const SubscriptionTemplatesList = (props) => {
  const {
    subscriptionTemplatesState,
    subscriptionTemplatesSearch,
    subscriptionTemplatesFilters,
    subscriptionTemplates_actions,
    state,
    dispatch,
  } = useSubscriptionTemplates();

  return (
    <Page
      title="Templates d'abonnement"
      errors={subscriptionTemplatesState.errors}
      isLoading={subscriptionTemplatesState.isLoading}
      action={{
        to: "subscription-templates/create",
        text: "Créer un template d'abonnement",
      }}
      {...props}
    >
      <div className="">
        <SubscriptionTemplatesFilters />
        <div className="mt-3">
          <SubscriptionTemplatesListTable />
        </div>
        {subscriptionTemplatesSearch.count ? (
          <Pagination
            nbPages={Math.ceil(subscriptionTemplatesSearch.count / 25)}
            page={subscriptionTemplatesFilters.page}
            changePage={(p) =>
              subscriptionTemplates_actions.updatePage({
                ...subscriptionTemplatesFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default SubscriptionTemplatesList;
