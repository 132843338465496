import React from "react";

const PaymentMethodButton = ({ data, isActive }) => {
  return (
    <button
      type="button"
      className={`px-3 mb-2 py-2 btn d-flex align-items-center justify-content-between flex-row shadow-sm flex-fill border position-relative ${
        isActive ? "btn-secondary text-white" : "btn-white bg-white"
      }`}
    >
      <div>
        <i
          className={`pf mr-3 pf-${data.brand} p-2 rounded shadow-sm ${
            isActive ? "bg-white text-secondary" : "bg-secondary text-white"
          }`}
        />
      </div>
      <div>
        <div>{data.info}</div>
        <div>
          <small style={{ fontSize: 12 }}>{data.expireAt}</small>
        </div>
      </div>
      {isActive && (
        <i
          className={`check-icon fa fa-check ml-2 animated faster 
          position-absolute bg-success text-white border border-success shadow-sm 
                  `}
          style={{
            top: -7,
            right: -7,
            fontSize: 8,
            padding: 5,
            borderRadius: "100%",
            zIndex: "90",
          }}
        />
      )}
    </button>
  );
};

export default PaymentMethodButton;
