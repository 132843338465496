import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";
import rootActions from "../rootActions";

class customerActions extends rootActions {
  constructor(params) {
    super(params);
    this.modifyCustomerPassword = this.modifyCustomerPassword.bind(this);
  }

  modifyCustomerPassword(newPassword) {
    //user-customers/:id/password
    this.updateIsLoading(true);
    const customerId = this.state.customers.customer.id;
    dataService.patch(
      `${this.apiUrls.patchEntity}/${customerId}/password`,
      { password: newPassword },
      (datas) => {
        toast.success("Mot de passe modifié ✓");
      },
      this.updateErrors,
      () => this.updateIsLoading(false)
    );
  }
}

export default customerActions;
