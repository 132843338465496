import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { authActions } from "../../../context/actions/authActions";
import useStore from "../../../context/useStore";
import useLayout from "../../hooks/useLayout/useLayout";

const UserBox = ({ style = { position: "relative" } }) => {
  const [state, dispatch] = useStore();
  const auth_actions = authActions(state, dispatch);
  const [isOpen, setIsOpen] = useState(false);

  const { setLayout } = useLayout();

  useEffect(() => {
    document.addEventListener("click", documentClick);
  }, []);

  const documentClick = (e) => {
    if (e.target.id != "openUserBox") {
      setIsOpen(false);
    }
  };

  return (
    <div style={{ ...style }}>
      <i
        id="openUserBox"
        className="fa  fa-user-circle cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
        style={{ fontSize: 28 }}
      />

      <div
        className={`position-absolute shadow-sm animated faster ${isOpen ? "fadeInUp" : "d-none"}`}
        style={{
          width: 152,
          right: -11,
          top: 39,
          zIndex: "999",
        }}
      >
        <ul className="list-group">
          <li className="list-group-item bg-white  border-0 w-100 p-0">
            <Link
              to={`/managers/${state.auth.user.id}`}
              className="btn btn-link text-secondary text-decoration-none text-left  w-100"
            >
              Mon profil
            </Link>
          </li>

          <li className="list-group-item bg-white  border-0 w-100 p-0">
            <button
              className="btn btn-link text-secondary text-decoration-none text-left  w-100"
              onClick={auth_actions.logoutUser}
            >
              Se déconnecter
            </button>
          </li>
          <li className="list-group-item bg-white  border-0 w-100 p-0">
            <div className="d-center border-bottom " style={{ color: "#a7a7a7" }}>
              <small>Position du menu</small>
            </div>
            <div className="btn-group w-100 ">
              <button
                className="btn btn-sm d-flex justify-content-center"
                onClick={() => setLayout("layout1")}
              >
                <i className="fa fa-arrow-up text-grey" />
              </button>
              <button
                className="btn btn-sm d-flex justify-content-center"
                onClick={() => setLayout("layout2")}
              >
                <i className="fa fa-arrow-left text-grey" />
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserBox;
