import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";
import useCustomers from "../../../hooks/useCustomers/useCustomers";

const CustomersListTable = () => {
  const { isLoading, customersSearch, languages, customers_actions, state } = useCustomers();

  const getNbChests = (subscriptions) => {
    var nbChests = 0;
    subscriptions.forEach((sub) => {
      nbChests += sub.chests.length;
    });
    return nbChests;
  };

  return (
    <Table
      isLoading={isLoading}
      nbResults={customersSearch.count}
      datas={customersSearch.data}
      fields={[
        {
          name: "Nom",
          path: "*",
          render: (el) => tools.getFullname(el),
        },
        {
          name: "Email",
          path: "email",
        },
        {
          name: "Abo.",
          path: "subscriptions",
          render: (el) => <div>{el.length}</div>,
        },
        {
          name: "Coffres",
          path: "subscriptions",
          render: (el) => getNbChests(el),
        },
        {
          name: "Activé ?",
          path: "isActivated",
          render: (el) => (
            <i className={`${el ? "fa fa-check text-success" : "fa fa-times text-danger"}`} />
          ),
        },
        {
          name: "Créé le",
          path: "createdAt",
          render: (el) => tools.formatDate(el),
        },

        {
          name: "Dern. connexion",
          path: "*",
          render: (el) => {
            return (
              <>
                {el.isLegadriveV1 && <span className="badge bg-secondary">v1</span>}
                {el.lastConnectionAt && <div>{tools.formatDate(el.lastConnectionAt)}</div>}
              </>
            );
          },
        },
        {
          name: "Lien Stripe",
          path: "stripeCustomerId",
          render: (el) => (
            <a target="_blank" href={`https://dashboard.stripe.com/customers/${el}`}>
              <i className="fa fa-link" />
            </a>
          ),
        },
        {
          name: "Boutique",
          path: "origin",
        },
        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-secondary btn-sm me-1" to={`/customers/${el.id}`}>
                  Ouvrir
                </Link>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default CustomersListTable;
