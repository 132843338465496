import { atom, useAtom } from "jotai";
import { DateTime } from "luxon";
import React, { useState } from "react";
import dataService from "../../../../helpers/dataService";
import { useStatStore } from "./statContext/StatStore";
import { atoms } from "./statsAtoms";

function useStats(code) {
  const [statState, dispatch] = useStatStore();
  const [isLoading, setIsLoading] = useState(false);
  const [chartState, setChartState] = useAtom(atoms[code].atom);
  const [total, setTotal] = useState(0);
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const getMonths = () => {
    const { beginAt, endAt } = statState.filters;

    if (beginAt && endAt) {
      var start = DateTime.fromISO(beginAt);
      var end = DateTime.fromISO(endAt);
      var monthStart = start.get("month");
      var nbMonths = parseInt(Math.floor(end.diff(start, "months").toObject().months));
      var filterMonths = [];
      var count = 0;
      for (var i = monthStart; count <= nbMonths; i++) {
        if (i / 12 > 1) {
          i = 1;
        }
        count++;
        filterMonths.push(months[i - 1]);
      }
      return filterMonths;
    }
    return months;
  };

  var filterMonths = getMonths();

  const lineOptions = {
    chart: {
      height: 350,
      type: "line",
      stacked: true,
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },

    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: filterMonths,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  };

  const getDatas = () => {
    setIsLoading(true);

    dataService.post(
      `${atoms[code].url}`,
      { ...statState.filters },
      (datas) => {
        var allTotals = datas.map((o) => o.months.reduce((a, b) => a + b));

        setTotal(allTotals.reduce((a, b) => a + b));
        setChartState({
          line: {
            series: datas.map((o) => ({
              name: o.name,
              data: o.months,
            })),
            options: lineOptions,
          },
          pie: {
            series: datas.map((o) => o.months.reduce((a, b) => a + b)),
            options: {
              labels: datas.map((o) => o.name),
              legend: { position: "bottom" },
            },
          },
        });
      },
      (e) => {},
      () => setIsLoading(false)
    );
  };

  var hasDatas = chartState;

  return {
    isLoading,
    getDatas,
    chartState,
    hasDatas,
    total,
  };
}

export default useStats;
