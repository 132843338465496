import React from "react";

const CheckboxInput = ({
  inputClassName = "",
  disabled = false,
  name,
  value,
  k = -1,
  checked = false,
  labelClassname = "",
  change = () => {},
  required = false,
  label = false,
  error = {},
  labelStyle = {},
}) => {
  const valueChange = (e) => {
    var filteredValue = e.target.value;
    console.log("chfval", filteredValue);
    let changeEvent = {
      target: {
        name: name,
        value: e.target.checked ? true : false,
        checked: e.target.checked,
        type: "checkbox",
        k: k,
      },
    };

    change(changeEvent);
  };

  return (
    <div className={"form-check d-center "} style={{ height: 75 }}>
      <input
        type="checkbox"
        className={"form-check-input " + inputClassName}
        disabled={disabled ? "disabled" : false}
        name={name}
        id={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
        onChange={valueChange}
        checked={value ? "checked" : false}
      />
      <label
        className={"form-check-label mb-0 border-0 ms-2 " + labelClassname}
        style={{ ...labelStyle, cursor: "pointer", minHeight: "0", padding: 0 }}
        htmlFor={k != -1 ? "customSwitch" + name + "-" + k : "customSwitch" + name}
      >
        {label && (
          <span>
            {label}
            {required && <span className="text-danger ms-1">*</span>}
          </span>
        )}
      </label>
      {error[name] && (
        <small className={"form-text text-danger " + (error[name] ? "animated flash" : "")}>{error[name]}</small>
      )}
    </div>
  );
};

export default CheckboxInput;
