import React, { useState } from "react";

const Tabs = ({ tabs, className, initialTab = 0 }) => {
  const [currentTab, setCurrentTab] = useState(initialTab);

  return (
    <>
      <ul className={`nav ${className}`}>
        {tabs.map((tab, tk) => (
          <li className="nav-item">
            <a
              className={`nav-link border-bottom border-3 bg-default text-secondary me-1 ${
                tk == currentTab ? "active border-secondary " : ""
              } rounded-top`}
              aria-current="page"
              href="#"
              onClick={() => setCurrentTab(tk)}
            >
              {tab.title}
            </a>
          </li>
        ))}
      </ul>
      {tabs.map((tab, tk) => {
        return (
          <div className={`${tk == currentTab ? "d-block" : "d-none"}   border-top-0 bg-default `}>
            {tab.render({ currentTab, setCurrentTab })}
          </div>
        );
      })}
    </>
  );
};

export default Tabs;
