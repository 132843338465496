import React, { useEffect, useRef, useState } from "react";
import LabelContainer from "../LabelContainer";
import EmptyButton from "./EmptyButton";

const DigitsInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  params = [],
  ...props
}) => {
  const [digits, setDigits] = useState([]);

  useEffect(() => {
    initDigits();
  }, []);

  function initDigits() {
    var dg = [];
    params.forEach((p) => {
      dg.push({
        size: p.size,
        value: "",
        placeholder: p.placeholder,
      });
    });
    setDigits(dg);
  }
  function eraseDigits() {
    change({ target: { name: props.name, value: "" } });
    initDigits();
  }

  function updateData(e, dk) {
    var dg = JSON.parse(JSON.stringify(digits));

    if (e.target.value.length <= dg[dk].size) {
      dg[dk].value = e.target.value;
      setDigits(dg);
    }
    if (dg[dk].size == dg[dk].value.length && dk < dg.length - 1) {
      document.querySelector("#digit" + (dk + 1)).focus();
    }
    var finalValue = dg.map((d) => d.value).join("");
    var totalSize = 0;
    dg.forEach((d) => {
      totalSize += parseInt(d.size);
    });
    if (finalValue.length == totalSize) {
      change({ target: { name: props.name, value: finalValue } });
    }
    if (finalValue.length == 0) {
      change({ target: { name: props.name, value: "" } });
    }
  }

  function focusInput(e, dk) {
    if (e.target.value < digits[dk].size) {
      var inputs = document.querySelectorAll("#digit-control .form-control");
      for (var inpk in inputs) {
        if (inputs[inpk].value.length < digits[inpk].size) {
          inputs[inpk].focus();
          break;
        }
      }
    }
  }
  return (
    <>
      <LabelContainer {...props} value={value}>
        <div className="row m-0 p-0 w-100 " id="digit-control">
          {digits.map((d, dk) => {
            let placeholder = d.placeholder;
            if (!placeholder) {
              placeholder = "";
              for (var i = 0; i < d.size; i++) {
                placeholder += "_";
              }
            }
            return (
              <input
                key={"digit-" + dk}
                onClick={(e) => focusInput(e, dk)}
                placeholder={placeholder}
                id={"digit" + dk}
                name={"digit" + dk}
                className="form-control col text-center"
                value={d.value}
                onChange={(e) => updateData(e, dk)}
              />
            );
          })}
        </div>
      </LabelContainer>
      <div className="col-12 text-center mt-2">
        <button
          type="button"
          onClick={eraseDigits}
          className="btn btn-default btn-sm"
        >
          Effacer
        </button>
      </div>
    </>
  );
};

export default DigitsInput;
