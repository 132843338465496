import React, { useState, useEffect } from "react";
import Validation from "./Validation";
import CheckboxInput from "./Inputs/CheckboxInput";
import TextInput from "./Inputs/TextInput";
import NumberInput from "./Inputs/NumberInput";
import DateInput from "./Inputs/DateInput";
import SelectInput from "./Inputs/SelectInput";
import EmailInput from "./Inputs/EmailInput";
import TelInput from "./Inputs/TelInput";
import FloatInput from "./Inputs/FloatInput";
import PasswordInput from "./Inputs/PasswordInput";
import RangeInput from "./Inputs/RangeInput";
import CheckboxListInput from "./Inputs/CheckboxListInput";
import CheckBtnListInput from "./Inputs/CheckBtnListInput";
import BtnListInput from "./Inputs/BtnListInput";
import AutocompleteInput from "./Inputs/AutocompleteInput";
import TextareaInput from "./Inputs/TextareaInput";
import ColorInput from "./Inputs/ColorInput";
import SearchInput from "./Inputs/SearchInput";
import Tip from "./Tip/Tip";
import DigitsInput from "./Inputs/DigitsInput";
import ImageInput from "./Inputs/ImageInput";

const Control = (props) => {
  const componentTypes = {
    checkbox: CheckboxInput,
    color: ColorInput,
    text: TextInput,
    search: SearchInput,
    textarea: TextareaInput,
    number: NumberInput,
    float: FloatInput,
    date: DateInput,
    email: EmailInput,
    select: SelectInput,
    tel: TelInput,
    password: PasswordInput,
    range: RangeInput,
    checkboxList: CheckboxListInput,
    btnList: BtnListInput,
    checkBtnList: CheckBtnListInput,
    autocomplete: AutocompleteInput,
    digits: DigitsInput,
    image: ImageInput,
  };

  var TargetComponent = componentTypes[props.type];
  const [isComplete, setIsComplete] = useState(true);

  useEffect(() => {
    validateInput();
  }, [props.value, props.isValid]);

  useEffect(() => {
    if (isComplete && props.setError) {
      props.setError({ ...props.error, [props.name]: false });
    }
  }, [isComplete]);

  useEffect(() => {
    if (props.error && props.error[props.name]) {
      setIsComplete(false);
    }
  }, [props.error]);

  const validateInput = () => {
    var inputValid = false;
    if (typeof props.isValid == "undefined") {
      inputValid = Validation.validate(props.type, props.value, props.required);
    } else {
      inputValid = props.isValid;
    }
    setIsComplete(inputValid);
    try {
      props.setIsValid(inputValid);
    } catch (err) {}
  };

  return !props.type ? (
    <small>la prop type a été oubliée</small>
  ) : (
    <div
      style={props.containerStyle || {}}
      className={`
         ${props.className || ""} ${props.margin || ""} ${props.tip ? "position-relative" : ""}
      `}
    >
      {props.tip ? <Tip tip={props.tip} /> : null}
      <TargetComponent {...props} isValid={isComplete} />
    </div>
  );
};

export default Control;
