import React from "react";
import Timer from "../../common/Timer/Timer";
import useStore from "../../../context/useStore";

const LoginErrorBox = (props) => {
  const [state, dispatch] = useStore();

  return state.errors.nbAttempts > 0 || !props.canLoggin ? (
    <div className="w-75 my-3 text-center d-flex flex-column justify-content-center align-items-center animated zoomIn">
      {state.errors.nbAttempts > 0 && (
        <span className="text-primary">
          {state.errors.nbAttempts} tentatives restantes
        </span>
      )}
      {!props.canLoggin && (
        <>
          <span className="text-danger mb-3">
            L'accès à votre compte est temporairement bloqué suite à plusieurs
            tentatives de connexion infructueuses.
          </span>
          <Timer timeLimit={props.timer} timerFinish={props.timerFinish} />
        </>
      )}
    </div>
  ) : null;
};

export default LoginErrorBox;
