const menuItems = [
  {
    name: "Accueil",
    path: "/",
    icon: "fa fa-home",
  },
  {
    name: "Documents",
    path: "/documents",
    icon: "fa fa-file-alt",
  },
  {
    name: "Clients",
    path: "/customers",
    icon: "fa fa-users",
  },
  {
    name: "Coffres",
    path: "/all-chests",
    icon: "fa fa-archive",
  },

  {
    name: "Paramètres",
    path: "#",
    icon: "fa fa-cog",
    dataPriv: "view_parameters",
    items: [
      {
        name: "Boutiques",
        path: "/shops",
        icon: "fa fa-store",
      },
      {
        name: "Templates d'abonnement",
        path: "/subscription-templates",
        icon: "fa fa-dna",
      },
      {
        name: "Managers",
        path: "/managers",
        icon: "fa fa-users",
      },
      {
        name: "Crons",
        path: "/crons",
        icon: "fa fa-cog",
        dataPriv: "view_crons",
      },
    ],
  },
];
export default menuItems;
