import { Provider } from "jotai";
import { DateTime } from "luxon";
import React from "react";
import Page from "../../../common/layout/Page";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import EvoAndChart from "./EvoAndChart";
import ReloadAllBtn from "./ReloadAllBtn";
import StatContainer from "./StatContainer";
import statReducer from "./statContext/statReducer";
import { StatStoreProvider } from "./statContext/StatStore";
import StatFilters from "./StatFilters";

const Stats = () => {
  return (
    <Page title="Statistiques">
      <StatStoreProvider
        initialState={{
          filters: {
            beginAt: DateTime.local().minus({ months: 12 }).toISO(),
            endAt: DateTime.local().toISO(),
          },
          isLoading: false,
        }}
        reducer={statReducer}
        key={"stats"}
      >
        <StatFilters />
        <ReloadAllBtn />
        <StatContainer title="Nombre d'utilisateurs" index={1}>
          <EvoAndChart code="userCustomers" />
        </StatContainer>
        <StatContainer title="Nombre de coffres" index={2}>
          <EvoAndChart code="chests" />
        </StatContainer>
        <StatContainer title="Nombre de téléchargements de documents" index={3}>
          <EvoAndChart fullWidth code="files" />
        </StatContainer>
        <StatContainer title="Nombre de partages" index={4}>
          <EvoAndChart code="links" />
        </StatContainer>
        <StatContainer title="Motifs de résiliation" index={5}>
          <EvoAndChart code="canceledSubscriptions" />
        </StatContainer>
      </StatStoreProvider>
    </Page>
  );
};

export default Stats;
