import React, { useEffect } from "react";
import useStore from "../../context/useStore";
import { Route, Redirect } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import RenewPasswordLoggedIn from "../pages/RenewPassword/RenewPasswordLoggedIn";
import Index from "../pages/Index/Index";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [state] = useStore();

    return state.auth.isLoading ? (
        <ClipLoader />
    ) : (
        <Route
            {...rest}
            render={(props) =>
                state.auth.isAuthenticated == true ? (
                    state.auth.user.mustRenewPassword ? (
                        <RenewPasswordLoggedIn />
                    ) : (
                        <Component {...props} />
                    )
                ) : (
                    <Index />
                )
            }
        />
    );
};

export default PrivateRoute;
