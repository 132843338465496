import React from "react";
import useChests from "../../../hooks/useChests/useChests";
import ChestCard from "./ChestCard/ChestCard";
import chestReducer from "./chestContext/chestReducer";
import { ChestStoreProvider } from "./chestContext/ChestStore";
import Loader from "../../../common/Loader";

const ChestCards = () => {
  const { chestsState, isLoading } = useChests();

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {chestsState.chests.map((chest, ck) => (
        <ChestStoreProvider
          initialState={{
            ...chest,
          }}
          reducer={chestReducer}
          key={"chest" + ck}
        >
          <ChestCard gState={chest} />
        </ChestStoreProvider>
      ))}
    </div>
  );
};

export default ChestCards;
