import React from "react";
import Control from "../../../common/Controls/Control";
import { updateFilters } from "./statContext/statReducer";
import { useStatStore } from "./statContext/StatStore";

const StatFilters = () => {
  const [statState, dispatch] = useStatStore();

  console.log("statState", statState);
  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(updateFilters({ ...statState.filters, [name]: value }));
  };

  return (
    <div className="row bg-white p-3 rounded mb-3 mx-0">
      <div className="col-12 col-md-6">
        <Control
          label="Date début"
          type="date"
          name="beginAt"
          value={statState.filters?.beginAt}
          change={handleChange}
        />
      </div>
      <div className="col-12 col-md-6">
        <Control
          label="Date fin"
          type="date"
          name="endAt"
          value={statState.filters?.endAt}
          change={handleChange}
        />
      </div>
    </div>
  );
};

export default StatFilters;
