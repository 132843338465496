import React from "react";

const StatStore = React.createContext();

export const useStatStore = () => React.useContext(StatStore);

export const StatStoreProvider = ({ children, initialState, reducer }) => {
  const [statState, dispatch] = React.useReducer(reducer, initialState);

  return <StatStore.Provider value={[statState, dispatch]}>{children}</StatStore.Provider>;
};
