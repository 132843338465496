import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Loader from "react-spinners/BarLoader";
import useDocumentUploader from "./useDocumentUploader";

const DocumentUploader = ({ itemId = null, onUploaded = () => {} }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    handleSubmit,
    errors,
    isUploading,
    clearFile,
    progress,
    setFile,
    file,
    isUploaded,
    setIsUploaded,
  } = useDocumentUploader({ itemId, onUploaded });

  return (
    <AnimatePresence exitBeforeEnter>
      {isUploading ? (
        <div className="d-center bg-verylight" style={{ minHeight: 140 }}>
          <Loader text={`Envoi en cours ${progress}%`} />
        </div>
      ) : (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          style={{ overflow: "hidden" }}
          className={`${isUploaded ? "border" : ""}row mx-0   bg-verylight rounded`}
        >
          <div className="col-12 mt-2 mt-md-0 px-0">
            <div
              className={`${
                isUploaded ? "" : "bg-white"
              } rounded w-100 h-100 d-center text-center cursor-pointer text-secondary`}
              style={{
                border: file || isUploaded ? "none" : "2px dashed",
              }}
            >
              {file && !isUploaded ? (
                <div className="d-center flex-column animated zoomIn faster">
                  <i className="fa fa-3x fa-file mb-2" />
                  {file.name}
                  <button className="btn btn-link" onClick={clearFile}>
                    Retirer ce fichier
                  </button>
                </div>
              ) : isUploaded ? (
                <div>
                  <i className="fa fa-3x fa-check" />
                </div>
              ) : (
                <div {...getRootProps()} className="w-100 d-center p-3" style={{ minHeight: 140 }}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div>Déposez le document ici ...</div>
                  ) : (
                    <div>
                      Glissez-déposez un fichier ici,
                      <br /> ou cliquez pour parcourir vos dossiers
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {!isUploaded && file && (
            <div className="col-12 mt-2 px-0 animated fadeInUp faster">
              <div className="d-center flex-row h-100">
                <button
                  className="btn btn-success flex-fill shadow-sm rounded-right-0"
                  onClick={handleSubmit}
                >
                  <i className="fa fa-upload me-2" />
                  <span>Envoyer</span>
                </button>
              </div>
            </div>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default DocumentUploader;
