import React from "react";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useStats from "./useStats";
import { atoms } from "./statsAtoms";

const ReloadAllBtn = () => {
  const reloadAll = () => {
    Object.keys(atoms).forEach((entry) => {
      document.querySelector(`#${entry}Btn`).click();
    });
  };

  return (
    <SaveBtn
      className="mb-3"
      save={reloadAll}
      text={
        <>
          <i className="fa fa-refresh me-2" />
          Actualiser toutes les données
        </>
      }
    />
  );
};

export default ReloadAllBtn;
