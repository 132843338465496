export const allChestModel = () => ({
  firstname: "",
  lastname: "",
  role: "",
  email: "",
  password: "",
  isActivated: false,
  shops: [],
});

export const allChestInitialState = {
  isLoading: false,
  allChests: [],
  allChestsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  allChestsSearch: [],
  allChest: allChestModel(),
  errors: {},
};

export default function (state = allChestInitialState, action) {
  switch (action.type) {
    case "UPDATE_ALLCHESTS_FILTERS":
      return {
        ...state,
        allChestsFilters: action.payload,
      };
    case "UPDATE_ALLCHESTS_SEARCH":
      return {
        ...state,
        allChestsSearch: action.payload,
      };
    case "UPDATE_ALLCHESTS":
      return {
        ...state,
        allChests: action.payload,
      };

    case "UPDATE_ALLCHESTS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_ALLCHESTS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_ALLCHEST":
      return {
        ...state,
        allChest: action.payload,
      };
    default:
      return state;
  }
}
