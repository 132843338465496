import React from "react";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import useShops from "../../../hooks/useShops/useShops";

import ShopsFilters from "./ShopsFilters";
import ShopsListTable from "./ShopsListTable";

const ShopsList = (props) => {
  const { shopsState, shopsSearch, shopsFilters, shops_actions, state, dispatch } = useShops();

  return (
    <Page
      title="Shops"
      errors={shopsState.errors}
      isLoading={shopsState.isLoading}
      action={{
        to: "shops/create",
        text: "Créer une boutique",
      }}
      {...props}
    >
      <div className="">
        <ShopsFilters />
        <div className="mt-3">
          <ShopsListTable />
        </div>
        {shopsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(shopsSearch.count / 25)}
            page={shopsFilters.page}
            changePage={(p) =>
              shops_actions.updatePage({
                ...shopsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default ShopsList;
