import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { customerModel } from "../../../../context/reducers/customerReducers";
import tools from "../../../../helpers/tools";
import Page from "../../../common/layout/Page";
import Tabs from "../../../common/Tabs/Tabs";
import useCustomers from "../../../hooks/useCustomers/useCustomers";
import ChestsList from "../../Chests/ChestsList/ChestsList";
import CustomerSubscriptionsList from "../CustomerSubscriptionsList/CustomerSubscriptionsList";

import CustomerInformationsForm from "./CustomerInformationsForm";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CustomersDetail = (props) => {
  const { customers_actions, customer, isLoading } = useCustomers();

  let customerId = props?.match?.params?.id;
  const query = useQuery();

  useEffect(() => {
    customers_actions.getEntity(customerId);
    customers_actions.updateEntity(customerModel());
  }, []);

  return (
    <Page
      title={`${tools.getFullname(customer)}`}
      back={!props.noBack ? `/customers` : null}
      isLoading={isLoading}
    >
      <Tabs
        className="mt-3"
        initialTab={query.get("tab") || 0}
        tabs={[
          {
            title: "Informations",
            render: ({ setCurrentTab }) => (
              <CustomerInformationsForm match={{ params: { customerId } }} />
            ),
          },
          {
            title: "Abonnements",
            render: ({ setCurrentTab }) => (
              <CustomerSubscriptionsList
                match={{ params: { customerId } }}
                goToChestTab={() => setCurrentTab(2)}
              />
            ),
          },
          {
            title: "Coffres",
            render: ({ setCurrentTab }) => <ChestsList match={{ params: { customerId } }} />,
          },
        ]}
      />
    </Page>
  );
};

export default CustomersDetail;
