import React from "react";
import { Link, withRouter } from "react-router-dom";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";
import useSubscriptions from "../../../hooks/useSubscriptions/useSubscriptions";

const SubscriptionsListTable = (props) => {
  const { shopId } = props?.match?.params;
  const { isLoading, subscriptionsSearch, sub, subscriptions_actions, subscriptions } = useSubscriptions({
    shopId,
  });

  return (
    <Table
      isLoading={isLoading}
      nbResults={subscriptions.length}
      datas={subscriptions}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Montant",
          path: "amount",
          render: (el) => tools.truenumberWithSpaces(el / 100) + " €",
        },
        {
          name: "Activé",
          path: "isActivated",
          render: (el) => <i className={`${el ? "fa fa-check text-success" : "fa fa-times text-danger"}`} />,
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-secondary btn-sm me-1" to={`/shops/${shopId}/subscriptions/${el.id}`}>
                  Ouvrir
                </Link>
                <button className="btn btn-danger btn-sm" onClick={() => subscriptions_actions.deleteEntity(el.id)}>
                  <i className="fa fa-times  text-white" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default withRouter(SubscriptionsListTable);
