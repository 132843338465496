import React from "react";

const ChestInfo = ({ label, value }) => {
  return (
    <div>
      <strong>{label}</strong> : <small>{value}</small>
    </div>
  );
};

export default ChestInfo;
