import subscriptionTemplateActions from "../../../context/actions/subscriptionTemplateActions";
import useStore from "../../../context/useStore";

const useSubscriptionTemplates = () => {
  const [state, dispatch] = useStore();
  const subscriptionTemplates_actions = new subscriptionTemplateActions({
    state,
    dispatch,
    modelName: "subscriptionTemplates",
    entityName: "subscriptionTemplate",
    apiUrls: {
      search: "subscription-templates/search",
      getEntity: "subscription-templates/:id",
      patchEntity: "subscription-templates/:id",
      deleteEntity: "subscription-templates/:id",
      postEntity: "subscription-templates",
      getEntities: "subscription-templates",
    },
    reducerNames: {
      filters: "UPDATE_SUBSCRIPTION_TEMPLATES_FILTERS",
      search: "UPDATE_SUBSCRIPTION_TEMPLATES_SEARCH",
      loading: "UPDATE_SUBSCRIPTION_TEMPLATES_LOADING",
      list: "UPDATE_SUBSCRIPTION_TEMPLATES",
      entity: "UPDATE_SUBSCRIPTION_TEMPLATE",
      errors: "UPDATE_SUBSCRIPTION_TEMPLATES_ERRORS",
    },
  });
  const { updateSubscriptionTemplatesFilters, getSubscriptionTemplate, deleteSubscriptionTemplate } =
    subscriptionTemplates_actions;
  const subscriptionTemplatesState = state.subscriptionTemplates;
  const subscriptionTemplatesFilters = subscriptionTemplatesState.subscriptionTemplatesFilters;
  const subscriptionTemplatesErrors = subscriptionTemplatesState.errors;
  const { subscriptionTemplate, isLoading, subscriptionTemplatesSearch } = subscriptionTemplatesState;

  return {
    state,
    dispatch,
    subscriptionTemplates_actions,
    subscriptionTemplatesState,
    subscriptionTemplatesSearch,
    subscriptionTemplatesFilters,
    subscriptionTemplatesErrors,
    subscriptionTemplate,
    isLoading,
    deleteSubscriptionTemplate,
    constants: state.constants.items,
  };
};

export default useSubscriptionTemplates;
