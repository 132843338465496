import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import dataService from "../../helpers/dataService";
import rootActions from "../rootActions";

class managerActions extends rootActions {
  constructor(params) {
    super(params);
    this.modifyManagerPassword = this.modifyManagerPassword.bind(this);
    this.updateEntityAttribute = this.updateEntityAttribute.bind(this);
  }

  updateEntityAttribute(event) {
    const entity = this.state[this.modelName][this.entityName];
    const { name, value } = event.target;
    let newEntity = {};
    let additionnalAttribute = {};
    if (name == "role" && value != "OPERATOR") {
      additionnalAttribute = { shops: [] };
    }
    newEntity = {
      ...entity,
      [name]: value,
      ...additionnalAttribute,
    };

    this.updateEntity(newEntity);
  }

  modifyManagerPassword(newPassword) {
    //user-managers/:id/password
    this.updateIsLoading(true);
    const managerId = this.state.managers.manager.id;
    const path = generatePath(this.apiUrls.patchEntity, { id: managerId });
    dataService.patch(
      `${path}/password`,
      { password: newPassword },
      (datas) => {
        toast.success("Mot de passe modifié ✓");
      },
      this.updateErrors,
      () => this.updateIsLoading(false)
    );
  }
}

export default managerActions;
