import documentActions from "../../../context/actions/documentActions";
import useStore from "../../../context/useStore";

const useDocuments = () => {
  const [state, dispatch] = useStore();
  const documents_actions = new documentActions({
    state,
    dispatch,
    modelName: "documents",
    entityName: "document",
    apiUrls: {
      search: "documents/search",
      getEntity: "documents/:id",
      getEntities: "queues/FILE",
      patchEntity: "documents/:id",
      postEntity: "documents",
      deleteEntity: "documents/:id",
    },
    reducerNames: {
      filters: "UPDATE_DOCUMENTS_FILTERS",
      search: "UPDATE_DOCUMENTS_SEARCH",
      loading: "UPDATE_DOCUMENTS_LOADING",
      list: "UPDATE_DOCUMENTS",
      entity: "UPDATE_DOCUMENT",
      errors: "UPDATE_DOCUMENTS_ERRORS",
    },
  });
  const { updateDocumentsFilters, getDocument, deleteDocument } = documents_actions;
  const documentsState = state.documents;
  const documentsFilters = documentsState.documentsFilters;
  const documentsErrors = documentsState.errors;
  const { document, isLoading, documentsSearch } = documentsState;

  const documentsFiltered = documentsState.documents.filter((d) => {
    var isStatusValid = true;
    var isCompanyNameValid = true;
    var isSirenValid = true;
    var isEmailValid = true;
    var isCustomerNameValid = true;
    if (documentsFilters.filters.status.length) {
      isStatusValid = documentsFilters.filters.status.includes(d.status);
    }
    if (documentsFilters.filters.companyName.length > 0) {
      isCompanyNameValid = d.dataType.chest.companyName
        .toLowerCase()
        .includes(documentsFilters.filters.companyName.toLowerCase());
    }
    if (documentsFilters.filters.siren.length > 0) {
      isSirenValid = d.dataType.chest.siren.toLowerCase().includes(documentsFilters.filters.siren.toLowerCase());
    }
    if (documentsFilters.filters.email.length > 0) {
      isEmailValid = d.dataType.chest.userCustomer.email
        .toLowerCase()
        .includes(documentsFilters.filters.email.toLowerCase());
    }
    if (documentsFilters.filters.customerName.length > 0) {
      isCustomerNameValid = (d.dataType.chest.userCustomer.lastname + " " + d.dataType.chest.userCustomer.firstname)
        .toLowerCase()
        .includes(documentsFilters.filters.customerName.toLowerCase());
    }
    return isStatusValid && isCompanyNameValid && isSirenValid && isEmailValid && isCustomerNameValid;
  });

  return {
    state,
    dispatch,
    documents_actions,
    documentsState,
    documentsSearch,
    documentsFilters,
    documentsErrors,
    document,
    isLoading,
    deleteDocument,
    constants: state.constants.items,
    documentsFiltered,
  };
};

export default useDocuments;
