import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import useShops from "../../../hooks/useShops/useShops";
import useSubscriptions from "../../../hooks/useSubscriptions/useSubscriptions";
import useSubscriptionTemplates from "../../../hooks/useSubscriptionTemplates/useSubscriptionTemplates";

import SubscriptionsFilters from "./SubscriptionsFilters";
import SubscriptionsListTable from "./SubscriptionsListTable";

const SubscriptionsList = (props) => {
  const { subscriptionsState, subscriptionsSearch, subscriptionsFilters, subscriptions_actions, state, dispatch } =
    useSubscriptions();
  const { shops_actions } = useShops();
  const { subscriptionTemplates_actions } = useSubscriptionTemplates();

  let shopId = props?.match?.params?.shopId;
  let shopName = "";
  if (shopId) {
    shopName = state.shops.shops?.find((d) => d.id == shopId);
  }

  useEffect(() => {
    shops_actions.getList();
  }, []);

  return (
    <Page
      title={`Abonnements ${shopName ? "de " + shopName.name : ""}`}
      errors={subscriptionsState.errors}
      isLoading={subscriptionsState.isLoading}
      back={`/shops`}
      action={{
        to: "subscriptions/create",
        text: "Créer un abonnement",
      }}
      {...props}
    >
      <div className="">
        <SubscriptionsFilters shopId={shopId} />
        <div className="mt-3">
          <SubscriptionsListTable shopId={shopId} />
        </div>
        {subscriptionsSearch.count ? (
          <Pagination
            nbPages={Math.ceil(subscriptionsSearch.count / 25)}
            page={subscriptionsFilters.page}
            changePage={(p) =>
              subscriptions_actions.updatePage({
                ...subscriptionsFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default SubscriptionsList;
