import { DateTime } from "luxon";

const initialState = {
  filters: {
    beginAt: DateTime.local().minus({ months: 12 }).toISO(),
    endAt: DateTime.local().toISO(),
  },
  isLoading: false,
};

export const updateFilters = (payload) => ({
  type: "UPDATE_FILTERS",
  payload,
});
export const initStat = (payload) => ({
  type: "INIT_STAT",
  payload,
});

export const setIsLoading = (payload) => ({
  type: "IS_LOADING",
  payload,
});
export const populateStatDocuments = (payload) => ({
  type: "POPULATE_STAT_DOCUMENTS",
  payload,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case "INIT_STAT":
      return action.payload;
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "UPDATE_FILTERS":
      return { ...state, filters: action.payload };

    default:
      return state;
  }
}
