import { motion } from "framer-motion";
import React, { useState } from "react";

const Tip = ({ tip }) => {
    const [isOpen, setIsOpen] = useState(false);

    const openTip = () => {
        setIsOpen(true);
    };
    const closeTip = () => {
        setIsOpen(false);
    };

    return (
        <div
            style={{
                position: "absolute",
                right: 5,
                top: 5,
                minWidth: 180,
                zIndex: "89",
            }}
        >
            {isOpen && (
                <motion.div
                    initial={{
                        x: -10,
                        opacity: 0,
                    }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -10, opacity: 0 }}
                    className="position-absolute bg-light  rounded"
                    style={{
                        right: 0,
                        bottom: 0,
                        padding: 10,
                    }}
                >
                    {tip}
                </motion.div>
            )}
            <button
                className="btn p-0 bg-trans border-0 position-absolute"
                style={{
                    right: 0,
                    top: 0,
                }}
                onMouseEnter={openTip}
                onMouseLeave={closeTip}
            >
                <i className="fa fa-question-circle text-white" />
            </button>
        </div>
    );
};

export default Tip;
