import { DateTime } from "luxon";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import useStore from "../../../../context/useStore";
import colors from "../../../../helpers/colors";
import dataService from "../../../../helpers/dataService";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useDocuments from "../../../hooks/useDocuments/useDocuments";
import { initDocument, updateDocument } from "../documentContext/documentReducer";
import { useDocumentStore } from "../documentContext/DocumentStore";
import DocumentTitle from "./DocumentTitle";
import DocumentUploader from "./DocumentUploader/DocumentUploader";

const DocumentCard = ({ gState = {} }) => {
  const [state] = useStore();
  const [document, dispatch] = useDocumentStore();
  const { QUEUE_STATUS, FILE_TYPES } = state.constants.items;
  const [isSaving, setIsSaving] = useState(false);
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const { documents_actions, documentsState, isLoading } = useDocuments();

  const onUploaded = (id) => {
    // const newList = [
    //   ...documentsState.documents.map((d) => (d.id == id ? { ...d, isDone: true } : d)),
    // ];
    // documents_actions.updateEntities(newList);
    dispatch(updateDocument({ ...document, isDone: true }));
  };

  const handleChangeDocument = (e) => {
    var { name, value } = e.target;
    // const newList = [
    //   ...documentsState.documents.map((d) => (d.id == document.id ? { ...d, [name]: value } : d)),
    // ];
    // documents_actions.updateEntities(newList);
    dispatch(updateDocument({ ...document, [name]: value }));
  };

  const saveDocument = () => {
    setIsSaving(true);
    dataService.patch(
      `queues/${document.id}`,
      document,
      (data) => {
        toast.success("Enregistrement réussi");
        setIsSaved(true);
      },
      (err) => {},
      () => setIsSaving(false)
    );
  };

  useEffect(() => {
    setShowSaveBtn(false);
    setIsSaved(false);
    dispatch(initDocument(gState));
  }, [gState]);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (document.status || document.comment) {
      setShowSaveBtn(true);
      setIsSaved(false);
    }
  }, [document.status, document.comment]);

  var { isDone } = document;

  const docStatus = tools.findIn(QUEUE_STATUS, document.status, "id");

  return (
    <div
      className={`mb-2 mt-5 border  p-3 rounded ${
        isDone ? "bg-default opacity-75 " : "bg-light shadow-sm"
      }`}
    >
      <div className="row">
        <div className={`col-12 col-md-4 ${isDone ? "text-decoration-line-through" : ""}`}>
          <div className="d-flex justify-content-between flex-column h-100">
            <div className="d-flex justify-content-start flex-column">
              <DocumentTitle />
            </div>
            {!isDone && (
              <div
                className={`text-white bg-${docStatus.color} px-2 bg-info text-white rounded-top fs-3`}
                style={{
                  position: "absolute",
                  top: -40,
                }}
              >
                <i className={`fa fa-${docStatus.icon} me-2`} />
                {docStatus.name}
              </div>
            )}
          </div>
        </div>
        <div className={`col-12 col-md-4 ${isDone ? "text-decoration-line-through" : ""}`}>
          <div className="">
            <div className={`  d-center flex-row h-100`}>
              <Control
                label="Statut"
                name="status"
                type="btnList"
                datas={QUEUE_STATUS}
                dataIndex="id"
                dataLabel="name"
                dataColor="color"
                value={document.status}
                change={handleChangeDocument}
                inputStyle={{ background: "white" }}
                className="w-100"
              />
            </div>
            <Control
              label="Commentaire"
              type="textarea"
              value={document.comment}
              change={handleChangeDocument}
              name="comment"
            />

            {showSaveBtn && !isSaved && (
              <SaveBtn
                type="success"
                save={saveDocument}
                isSaving={isSaving}
                className="animated fadeIn faster"
              />
            )}
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="mb-1">
            Envoyer un{" "}
            <strong className="text-secondary">
              {tools.findIn(FILE_TYPES, document.dataType.fileType, "id").name}
              <i className="fa fa-file-alt ms-1" />
            </strong>
          </div>
          <DocumentUploader itemId={document.id} onUploaded={onUploaded} />
        </div>
      </div>
      {document.isDone && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: `linear-gradient(0deg, rgb(249,249,249), rgba(0,0,0,0.001))`,
            zIndex: "3",
          }}
        ></div>
      )}
    </div>
  );
};

export default DocumentCard;
