const isoNames = {
    FR: "fr",
    EN: "gb",
};

const LangBadge = ({ lang, style }) => {
    return (
        <div className="badge badge-default me-1" style={{ ...style }}>
            <span className={`flag-icon me-1 flag-icon-` + isoNames[lang]}></span>
            {lang}
        </div>
    );
};

export default LangBadge;
