import React from "react";
import Control from "../../common/Controls/Control";
import FiltersContainer from "../../common/FiltersContainer/FiltersContainer";
import useDocuments from "../../hooks/useDocuments/useDocuments";

const DocumentFilters = () => {
  const {
    updateFilters,
    documentsState,
    documentsFilters,
    documentsErrors,
    documents_actions,
    constants,
  } = useDocuments();

  const { QUEUE_STATUS } = constants;

  return (
    <FiltersContainer filtersState={documentsState} isDataLoaded={true}>
      <div className="row">
        <div className="col-12 py-2 bg-white d-flex justify-content-between align-items-center rounded">
          <Control
            placeholder="🔎 Nom de société"
            name="companyName"
            type="text"
            value={documentsFilters.filters.companyName}
            change={documents_actions.updateFilters}
            errors={documentsErrors}
            className=" rounded  me-2"
            labelClassname="my-0"
          />
          <Control
            placeholder="🔎 Nom/prénom"
            name="customerName"
            type="text"
            value={documentsFilters.filters.customerName}
            change={documents_actions.updateFilters}
            errors={documentsErrors}
            className=" rounded  me-2"
            labelClassname="my-0"
          />
          <Control
            placeholder="🔎 Email"
            name="email"
            type="text"
            value={documentsFilters.filters.email}
            change={documents_actions.updateFilters}
            errors={documentsErrors}
            className=" rounded  me-2"
            labelClassname="my-0"
          />

          <Control
            placeholder="🔎 SIREN"
            name="siren"
            type="text"
            value={documentsFilters.filters.siren}
            change={documents_actions.updateFilters}
            errors={documentsErrors}
            className=" rounded  me-2"
            labelClassname="my-0"
          />
          <Control
            placeholder="🔎 Statut"
            name="status"
            type="checkBtnList"
            datas={QUEUE_STATUS}
            dataIndex="id"
            dataLabel="name"
            value={documentsFilters.filters.status}
            change={documents_actions.updateFilters}
            errors={documentsErrors}
            inputStyle={{ background: "white" }}
            className="px-2 rounded "
            labelClassname=""
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default DocumentFilters;
