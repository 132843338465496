import { Route, Switch } from 'react-router-dom';
import ActivateAccount from '../../component/pages/ActivateAccount/ActivateAccount';
import Logout from '../../component/pages/Logout/Logout';
import RenewPassword from '../../component/pages/RenewPassword/RenewPassword';

const publicRoutes = () => {
    return (
        <Switch>
            <Route path="/activate-account/:token/:email" exact component={ActivateAccount} />
            <Route path="/renew-password/:token/:email" exact component={RenewPassword} />
            <Route path="/logout" exact component={Logout} />
        </Switch>
    );
};

export default publicRoutes;
