import React, { useEffect } from "react";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import Page from "../../../common/layout/Page";
import { defaultEntityValue } from "../atoms/shopAtoms";
import useShopAtoms from "../atoms/useShopAtoms";

const ShopsDetail = (props) => {
  const { actions, entity, errors, isLoading } = useShopAtoms();

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      actions.getEntity(paramsId);
    } else {
      actions.updateEntity(defaultEntityValue);
    }
  }, []);

  return (
    <Page title={entity.name || "Boutiques"} back={`/shops`} isLoading={isLoading}>
      <FormContainer onSubmit={actions.submitEntity} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Nom"
              type="text"
              name="name"
              value={entity.name}
              change={actions.updateEntity}
              error={errors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Code"
              type="text"
              name="code"
              value={entity.code}
              change={actions.updateEntity}
              error={errors}
            />
          </div>
          <div className="col-12">
            <Control
              label="Description"
              type="textarea"
              name="description"
              value={entity.description}
              change={actions.updateEntity}
              error={errors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default ShopsDetail;
