import React, { useState, useEffect } from 'react';
import Control from '../../common/Controls/Control';
import PasswordControl from '../../common/PasswordControl/PasswordControl';
import Page from '../../common/layout/Page';
import Axios from 'axios';
import { API_URL } from '../../../config';
import Loader from '../../common/Loader';
import { Link } from 'react-router-dom';
import { authActions } from '../../../context/actions/authActions';
import useStore from '../../../context/useStore';
import { toast } from 'react-toastify';

var timerInterval = null;

const RenewPasswordLoggedIn = (props) => {
    const [state, dispatch] = useStore();
    var actions = authActions(state, dispatch);
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [errors, setErrors] = useState({});
    const [canSave, setCanSave] = useState(false);
    const [passwordsEqual, setPasswordsEqual] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        setErrors({});
        var errs = {};
        if (password != passwordConfirm || password.length == 0) {
            errs.passwordConfirm = 'Doit-être identique au premier';
        }

        if (Object.keys(errs).length === 0 && errs.constructor === Object) {
            setPasswordsEqual(true);
        } else {
            setPasswordsEqual(false);
        }
        setErrors(errs);
    }, [password, passwordConfirm]);

    function save() {
        if (canSave && passwordsEqual) {
            //save
            setIsLoading(true);
            Axios.patch(API_URL + 'user-managers/me/password', {
                password: password,
            })
                .then((res) => {
                    actions.loginUser({ login: state.auth.user.email, password });
                    toast.success('Votre mot de passe a bien été modifié.');
                    setIsSuccess(true);
                    setErrors({});
                })
                .catch((err) => {
                    if (err.response && err.response.data) {
                        setErrors(err.response.data);
                    }
                })
                .then(() => {
                    setIsLoading(false);
                });
        }
    }

    return (
        <Page errors={errors}>
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6 m-auto">
                    <div className="card">
                        <div className="card-header text-white">
                            <h4 className="mb-0 p-0 text-white">Changer mon mot de passe</h4>
                        </div>
                        {isLoading ? (
                            <div className="card-body  p-4 p-lg-5 p-md-4">
                                <Loader text="Vérification en cours" />
                            </div>
                        ) : isSuccess ? (
                            <div className="card-body text-center">
                                <i className="fa fa-check fa-4x text-success animated zoomIn" />
                                <p className="text-success  font-weight-bold mt-3 mb-4 font-weight-bold animated fadeIn">
                                    Votre mot de passe a bien été modifié.
                                </p>
                                <a href="/" className="btn btn-success animated fadeIn">
                                    Se connecter
                                </a>
                            </div>
                        ) : (
                            <div className="card-body p-4 p-lg-5 p-md-4">
                                <p>
                                    Pour garantir la sécurité de vos informations, vous devez
                                    changer votre mot de passe tous les ans.
                                </p>
                                <p>
                                    Veuillez saisir un nouveau mot de passe pour votre compte et le
                                    confirmer à l'aide des champs ci-dessous.{' '}
                                    <b>Vous ne pouvez pas réutiliser un ancien mot de passe.</b>
                                </p>
                                <Control
                                    className="mt-5"
                                    label="Choisissez un nouveau mot de passe"
                                    type="password"
                                    name="password"
                                    value={password}
                                    change={(e) => setPassword(e.target.value)}
                                    error={errors}
                                    isValid={!errors.password && password != '' && canSave}
                                    setCanSave={setCanSave}
                                />

                                <Control
                                    label="Confirmez votre mot de passe"
                                    type="password"
                                    name="passwordConfirm"
                                    value={passwordConfirm}
                                    change={(e) => setPasswordConfirm(e.target.value)}
                                    error={errors}
                                    isValid={!errors.passwordConfirm && passwordConfirm != ''}
                                />
                                <span className="text-danger">{errors.other}</span>
                                {canSave && passwordsEqual && (
                                    <button
                                        className={
                                            'btn mt-3 w-100 btn-success animated zoomIn faster'
                                        }
                                        onClick={save}
                                    >
                                        Modifier mon mot de passe
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Page>
    );
};

export default RenewPasswordLoggedIn;
