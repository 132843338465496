import { DateTime } from "luxon";
import React from "react";
import tools from "../../../helpers/tools";

const OwnerList = ({ owners }) => {
  return (
    <ul className="list-group">
      {owners.map((owner) => (
        <li
          style={{ listStyle: "none" }}
          key={`owner${tools.getFullname(owner)}`}
          className="mb-1 bg-default p-2 rounded border"
        >
          <div className="text-dark">
            <i className="fa fa-user text-info me-1" />
            {tools.getFullname(owner)}
          </div>
          <div className="badge bg-info text-white">{owner.quality}</div>
          <br />
          <small>
            Né(e) le{" "}
            {owner.birthdate && owner.birthdate !== "NC"
              ? DateTime.fromISO(owner.birthdate).toFormat("dd.MM.yyyy")
              : "NC"}
          </small>
        </li>
      ))}
    </ul>
  );
};

export default OwnerList;
