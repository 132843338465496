// "crud_organizations", //CRUD les organisations [DONE]
//     "crud_sites", //CRUD les sites [DONE]
//     "view_site_calendar", //Voir les calendriers des sites [DONE]
//     "crud_event_calendar", //CRUD les évènements sur les calendrier des sites [DONE]
//     "view_recipients", //Voir la liste des allocataires [DONE]
//     "update_recipients", //Modifier les infos des allocataires [DONE]
//     "unlock_recipient", //Débloquer le compte [DONE]
//     "resend_recipient_activation", //Renvoyer le lien d'activation [DONE]
//     "report_recipient_appointments", //Reporter les RDVS des allocataires [DONE]
//     "view_recipients_temp", //Voir la liste des allocataires temporaires [DONE]
//     "send_rdsp_link", //Envoyer le lien de la RDSP [DONE]
//     "crud_users", //CRUD utilisateur [DONE]
//     "manager_appointments", //Traiter des rendez-vous [DONE]
//     "view_anomalies", //Voir les anomalies [DONE]
//     "assign_anomaly", //Assigner une anomalie [DONE]
//     "manage_anomalies", //Voir et traiter mes anomalies [DONE]
//     "modify_constants", //Modifier les constantes dynamiques [DONE]
//     "crud_operators", //CRUD opérateurs [DONE]
//     "crud_themes", //CRUD thèmes [DONE]
//     "use_simulator", //Faire une simulation [DONE]
//     "create_simulator_rdsp", //Assigner le résultat d'une simulation à un allocataire [DONE]
//     "modify_rdsp", //modifier un RDSP [DONE]
//     "create_rdsp", //créer un RDSP [DONE]
//     "cron_anomaly", //lancer le cron des anomalies [DONE]
//     "file_exchange", //gérer les échanges de fichiers plats [DONE]
//     "view_sites", //Voir la liste des site (pour CSU uniquement)
//     "view_rdsp", //voir les RDSP d'un allocataire
//     "view_stats", //voir les stats
//     "create_recipient", //créer un allocataire
//     "view_passorientation", //voir le passorientation
//     "export_csv", //exporter des données en csv
//     "remove_anomaly" //supprimer une anomalie [DONE]
//     "edit_ep", //voir et éditer les EP
//     "create_recipient_ep" //créer un alloc man. pour EP
//     "finalize_ep", //"finaliser une EP"
//      "crud_reorientations" //liste des réorientations en cours
//     "edit_ep_details", //voir et éditer le détail EP
//     "manage_ep_invitations" // gérer les invitations EP
//      "view_ar_mail" // voir l'état des AR
//      "download_ar_mail" // télécharger des AR
//      "view_ep_calendar" //voir le calendrier des EP

const roleConfig = {
  SUPER_ADMIN: [],
  ADMIN: ["view_crons"],
  OPERATOR: ["view_parameters"],
};

export default roleConfig;
