import React from "react";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import useShopAtoms from "../atoms/useShopAtoms";

const ShopsFilters = () => {
  const { actions, searchFilters, searchList, errors } = useShopAtoms();
  return (
    <FiltersContainer onSubmit={actions.search} filtersState={searchFilters} isDataLoaded={searchList.data?.length}>
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={searchFilters.filters.terms}
            change={actions.updateFilters}
            errors={errors}
            inputStyle={{ background: "white" }}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default ShopsFilters;
