import React from "react";
import { Link } from "react-router-dom";
import { leftPanelActions } from "../../../context/actions/leftPanelActions";
import useStore from "../../../context/useStore";

const LeftPanel = () => {
    const [state, dispatch] = useStore();
    const actions = leftPanelActions(state, dispatch);
    const leftPanelProps = state.leftPanel;

    return (
        <>
            <div
                onClick={() => {
                    actions.updateLeftPanel({ isOpen: false, content: null });
                }}
                className="position-fixed w-100 h-100 align-items-center justify-content-center"
                style={{
                    display: leftPanelProps.isOpen ? "flex" : "none",
                    top: 0,
                    left: 0,
                    background: "rgb(0 0 0 / 54%)",
                    zIndex: "9999",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <div
                    className={
                        "position-fixed p-3 flex-column align-items-start " +
                        (leftPanelProps.isOpen ? "animated fadeInLeft faster" : "")
                    }
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        width: "42%",
                        height: "100%",
                        maxWidth: "400",
                        borderRadius: 5,
                        overflowY: "scroll",
                        left: 0,
                        top: 0,
                        zIndex: "9999",
                        minWidth: 450,
                        background: "rgba(31, 31, 31, 0.44)",
                        backdropFilter: "blur(10px)",
                    }}
                >
                    {leftPanelProps.backUrl && (
                        <Link className="btn btn-link" to={leftPanelProps.backUrl}>
                            <i className="fa fa-arrow-left me-2" />
                            retour à la page d'origine
                        </Link>
                    )}
                    {leftPanelProps.content}
                </div>
            </div>
        </>
    );
};

export default LeftPanel;
