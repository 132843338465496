import React from "react";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import useShops from "../../../hooks/useShops/useShops";

const ShopsFilters = () => {
  const { shopsState, shopsFilters, shopsErrors, shops_actions } = useShops();
  return (
    <FiltersContainer
      onSubmit={shops_actions.search}
      filtersState={shopsState}
      isDataLoaded={shopsState.shopsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={shopsFilters.filters.terms}
            change={shops_actions.updateFilters}
            errors={shopsErrors}
            inputStyle={{ background: "white" }}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default ShopsFilters;
