import React from "react";

const Details = ({ summary, children }) => {
  return (
    <details className="my-2">
      <summary className="fw-bold text-dark mb-2">{summary}</summary>
      <div className="ps-3 border-start border-secondary border-3 ms-1">{children}</div>
    </details>
  );
};

export default Details;
