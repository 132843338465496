import { generatePath } from "react-router-dom";
import dataService from "../../helpers/dataService";
import rootActions from "../rootActions";

class subscriptionActions extends rootActions {
  constructor(params) {
    super(params);
    this.deleteEntity = this.deleteEntity.bind(this);
  }

  deleteEntity(entityId) {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    this.updateIsLoading(true);
    const path = generatePath(this.apiUrls.deleteEntity, { id: entityId });
    dataService.remove(
      path,
      (datas) => this.getList(),
      this.updateErrors,
      () => this.updateIsLoading(false)
    );
  }
}

export default subscriptionActions;
