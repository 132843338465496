import React, { useEffect, useState } from "react";
import { shopModel } from "../../../../context/reducers/shopReducers";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import Page from "../../../common/layout/Page";
import useShops from "../../../hooks/useShops/useShops";

const ShopsDetail = (props) => {
  const { state, dispatch, shops_actions, shop, shopsErrors, isLoading, constants } = useShops();

  const [isModifyPassword, setIsModifyPassword] = useState(false);

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      shops_actions.getEntity(paramsId);
    } else {
      shops_actions.updateEntity(shopModel());
    }
  }, []);

  return (
    <Page title={shop.name || "Boutiques"} back={`/shops`} isLoading={isLoading}>
      <FormContainer onSubmit={shops_actions.saveEntity} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Nom"
              type="text"
              name="name"
              value={shop.name}
              change={shops_actions.updateEntityAttribute}
              error={shopsErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              disabled={shop.id}
              label="Code"
              type="text"
              name="code"
              value={shop.code}
              change={shops_actions.updateEntityAttribute}
              error={shopsErrors}
            />
          </div>
          <div className="col-12">
            <Control
              label="Description"
              type="textarea"
              name="description"
              value={shop.description}
              change={shops_actions.updateEntityAttribute}
              error={shopsErrors}
            />
          </div>
        </div>
      </FormContainer>
      {shop.id && (
        <div className="row mt-4">
          <div className="col-12">
            <div className="alert alert-dark">
              <h4>Code tiers partie</h4>
              <p className="mb-0">
                {`
                  <div id="shop-form" style="height:600px"></div>
                <script src="${process.env.REACT_APP_STORE_URL}external-components.js"></script>                
                <script>
                ExternalComponent({element: "shop-form", apiKey: "${shop.id}"}).render('#shop-form');
                </script>
                  `}
              </p>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

export default ShopsDetail;
