import React, { useState } from "react";
import dataService from "../../../helpers/dataService";
import Page from "../../common/layout/Page";
import SaveBtn from "../../common/SaveBtn/SaveBtn";

const Crons = (props) => {
  const [isLoading, setIsLoading] = useState("");

  const updateFiles = () => {
    setIsLoading("UPDATE_FILES");
    dataService.get(
      `crons/files/update-officials`,
      (data) => {},
      (err) => {},
      () => setIsLoading("")
    );
  };

  return (
    <Page title="Lancer manuellement les crons" {...props}>
      <ul className="list-group">
        <li className="list-group-item">
          <SaveBtn
            isSaving={isLoading == "UPDATE_FILES"}
            save={updateFiles}
            text="Récupération des documents officiels"
          />
        </li>
      </ul>
    </Page>
  );
};

export default Crons;
