export const customerSubscriptionModel = () => ({});

export const customerSubscriptionInitialState = {
  isLoading: false,
  customerSubscriptions: [],
  customerSubscriptionsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  customerSubscriptionsSearch: [],
  customerSubscription: customerSubscriptionModel(),
  errors: {},
};

export default function (state = customerSubscriptionInitialState, action) {
  switch (action.type) {
    case "UPDATE_CUSTOMER_SUBSCRIPTIONS_FILTERS":
      return {
        ...state,
        customerSubscriptionsFilters: action.payload,
      };
    case "UPDATE_CUSTOMER_SUBSCRIPTIONS_SEARCH":
      return {
        ...state,
        customerSubscriptionsSearch: action.payload,
      };
    case "UPDATE_CUSTOMER_SUBSCRIPTIONS":
      return {
        ...state,
        customerSubscriptions: action.payload,
      };

    case "UPDATE_CUSTOMER_SUBSCRIPTIONS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_CUSTOMER_SUBSCRIPTIONS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_CUSTOMER_SUBSCRIPTION":
      return {
        ...state,
        customerSubscription: action.payload,
      };
    default:
      return state;
  }
}
