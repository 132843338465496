import React from "react";
import { Link } from "react-router-dom";
import Table from "../../../common/Table/Table";
import useShopAtoms from "../atoms/useShopAtoms";

const ShopsListTable = () => {
  const { isLoading, searchList, actions } = useShopAtoms();

  return (
    <Table
      isLoading={isLoading}
      nbResults={searchList.count}
      datas={searchList.data}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Code",
          path: "code",
        },
        {
          name: "Description",
          path: "description",
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-secondary btn-sm me-1" to={`/shops/${el.id}`}>
                  Ouvrir
                </Link>
                <button className="btn btn-danger btn-sm" onClick={() => actions.deleteEntity(el.id)}>
                  <i className="fa fa-times  text-white" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default ShopsListTable;
