import React from "react";
import useCustomerSubscriptions from "../../../hooks/useCustomerSubscriptions/useCustomerSubscriptions";
import CustomerSubscriptionCard from "./CustomerSubscriptionCard/CustomerSubscriptionCard";
import customerSubscriptionReducer from "./customerSubscriptionContext/customerSubscriptionReducer";
import { CustomerSubscriptionStoreProvider } from "./customerSubscriptionContext/CustomerSubscriptionStore";
import Loader from "../../../common/Loader";

const CustomerSubscriptionCards = (props) => {
  const { customerSubscriptionsState, isLoading } = useCustomerSubscriptions();

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {customerSubscriptionsState.customerSubscriptions.map((customerSubscription, ck) => (
        <CustomerSubscriptionStoreProvider
          initialState={{
            ...customerSubscription,
          }}
          reducer={customerSubscriptionReducer}
          key={"customerSubscription" + ck}
        >
          <CustomerSubscriptionCard {...props} />
        </CustomerSubscriptionStoreProvider>
      ))}
    </div>
  );
};

export default CustomerSubscriptionCards;
