import axios from "axios";
import { API_URL, PLATFORM } from "../../config";
import tools from "../../helpers/tools";
import jwt_decode from "jwt-decode";
import dataService from "../../helpers/dataService";

export const authActions = (state, dispatch) => {
    function loginUser(userData) {
        // fetch api
        localStorage.clear();
        dataService.postWithOptions(
            `auth/login/BO`,
            userData,
            {
                withCredentials: true,
            },
            (datas) => {
                // Save to localStorage
                const token = datas;
                //console.log(datas);
                // Set token to ls
                localStorage.setItem("jwtToken", token);
                // Set token to Auth header
                //tools.setAuthToken(token);
                // Set current user

                dispatch({
                    type: "SET_CURRENT_USER",
                    payload: jwt_decode(token),
                });
            },
            (errors) => {
                dispatch({ type: "LOAD_CURRENT_USER", payload: false });
                dispatch({ type: "GET_ERRORS", payload: errors });
            }
        );
        //   axios
        //     .post(API_URL + "auth/login/BOA", userData, {
        //       withCredentials: true,
        //     })
        //     .then((res) => {
        //       // Save to localStorage
        //       const token = res.data;
        //       // Set token to ls
        //       localStorage.setItem("jwtToken", token);
        //       // Set token to Auth header
        //       //tools.setAuthToken(token);
        //       // Set current user

        //       dispatch({
        //         type: "SET_CURRENT_USER",
        //         payload: jwt_decode(token),
        //       });
        //     })
        //     .catch((err) => {
        //       tools.filterError(err, dispatch, "LOAD_CURRENT_USER");
        //     });
    }

    function logoutUser() {
        // Remove token from localStorage

        localStorage.removeItem("jwtToken");
        // Remove auth header for future requests
        axios.get(API_URL + "auth/logout").catch((err) => {
            console.log(err);
        });
        // Set current user to {} which will set isAuthenticated to false
        dispatch({ type: "CLEAR_CURRENT_USER", payload: {} });
        dispatch({ type: "CLEAR_ERRORS", payload: {} });
        //window.location.reload();
        //tools.filterAlert("info", "Vous avez été correctement déconnecté.");
    }

    return {
        loginUser,
        logoutUser,
    };
};
