import { DateTime } from "luxon";
import React, { useEffect } from "react";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import Details from "../../../../common/Details/Details";
import DocumentList from "../../../../common/DocumentList/DocumentList";
import OwnerList from "../../../../common/OwnerList/OwnerList";
import useChests from "../../../../hooks/useChests/useChests";
import { initChest } from "../chestContext/chestReducer";
import { useChestStore } from "../chestContext/ChestStore";
import ChestInfo from "./ChestInfo";
import KbisDropdown from "./KbisDropdown";

const ChestCard = ({ gState = {} }) => {
  const [state] = useStore();
  const { FILE_TYPES } = state.constants.items;
  const [chest, dispatch] = useChestStore();
  const { chests_actions, chestsState } = useChests({
    customerId: chest.chest.userCustomer,
  });

  useEffect(() => {
    dispatch(initChest(gState));
  }, [gState]);

  const onDocumentDeleted = (fileType, fileId) => {
    var currentChest = chestsState.chests.find((c) => c.chest.id == chest.chest.id);
    var updatedChest = {
      ...currentChest,
      files: {
        ...currentChest.files,
        [fileType]: currentChest.files[fileType].filter((f) => f.fileId != fileId),
      },
    };
    var newChests = chestsState.chests.map((c) =>
      c.chest.id == chest.chest.id ? updatedChest : c
    );

    chests_actions.updateEntities(newChests);
  };

  var countFiles = () => {
    var total = 0;
    Object.values(chest.files).forEach((files) => (total += files.length));
    return total;
  };

  return (
    <div className="row mb-3 px-3 py-4 bg-white mx-0 text-grey border rounded ">
      <div className="col-12 col-md-6">
        <div className="d-flex  flex-column flex-wrap  mb-3">
          <strong className="text-secondary" style={{ fontSize: 24 }}>
            {chest.chest.companyName}
          </strong>
          <small className="text-secondary">({chest.chest.siren})</small>
        </div>
        <ChestInfo label="Adresse" value={tools.getFullAddress(chest.establishments[0])} />
        <ChestInfo label="Forme juridique" value={chest.chest.legalForm} />
        <ChestInfo label="Activité" value={chest.chest.activity} />
        <ChestInfo label="Capital" value={chest.chest.shareCapital} />

        <ChestInfo
          label="Date enregistrement"
          value={DateTime.fromISO(chest.chest.registeredAt).toFormat("dd.MM.yyyy")}
        />
        <ChestInfo
          label="Activité démarrée le"
          value={DateTime.fromISO(chest.chest.startActivityAt).toFormat("dd.MM.yyyy")}
        />
        <Details summary={<>Gérants ({chest.owners.length})</>}>
          <OwnerList owners={chest.owners} />
        </Details>
      </div>

      <div className="col-12 col-md-6">
        <ChestInfo label="Type de coffre" value={chest.chest.type} />
        <ChestInfo
          label="Coffre officiel"
          value={
            chest.chest.isOfficial ? (
              <i className="fa fa-check text-success" />
            ) : (
              <i className="fa fa-times text-danger" />
            )
          }
        />

        <ChestInfo
          label="Coffre créé le"
          value={DateTime.fromISO(chest.chest.createdAt).toFormat("dd.MM.yyyy")}
        />
        <Details
          summary={
            <div className="d-inline-flex flex-row align-items-center ">
              <span className="me-2">Documents ({countFiles()})</span> <KbisDropdown />
            </div>
          }
        >
          <DocumentList
            files={chest.files}
            FILE_TYPES={FILE_TYPES}
            onDocumentDeleted={onDocumentDeleted}
          />
        </Details>
      </div>
    </div>
  );
};

export default ChestCard;
