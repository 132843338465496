import shopActions from "../../../context/actions/shopActions";
import useStore from "../../../context/useStore";

const useShops = () => {
  const [state, dispatch] = useStore();
  const shops_actions = new shopActions({
    state,
    dispatch,
    modelName: "shops",
    entityName: "shop",
    apiUrls: {
      search: "shops/search",
      getEntity: "shops/:id",
      getEntities: "shops",
      patchEntity: "shops/:id",
      postEntity: "shops",
      deleteEntity: "shops/:id",
    },
    reducerNames: {
      filters: "UPDATE_SHOPS_FILTERS",
      search: "UPDATE_SHOPS_SEARCH",
      loading: "UPDATE_SHOPS_LOADING",
      list: "UPDATE_SHOPS",
      entity: "UPDATE_SHOP",
      errors: "UPDATE_SHOPS_ERRORS",
    },
  });
  const { updateShopsFilters, getShop, deleteShop, getShops } = shops_actions;
  const shopsState = state.shops;
  const shopsFilters = shopsState.shopsFilters;
  const shopsErrors = shopsState.errors;
  const { shop, isLoading, shopsSearch } = shopsState;

  return {
    state,
    dispatch,
    shops_actions,
    shopsState,
    shopsSearch,
    shopsFilters,
    shopsErrors,
    shop,
    isLoading,
    deleteShop,
    constants: state.constants.items,
  };
};

export default useShops;
