import SaveBtn from "../SaveBtn/SaveBtn";

const FormContainer = ({ onSubmit = () => {}, isLoading, ...props }) => {
  return (
    <div className="px-4 py-3 bg-light rounded border">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        noValidate
      >
        {props.children}
        <div className="d-flex justify-content-end mt-3">
          <SaveBtn text="Enregistrer" typeBtn="submit" isSaving={isLoading} />
        </div>
      </form>
    </div>
  );
};

export default FormContainer;
