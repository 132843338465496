export const subscriptionModel = (override = {}) => ({
  shop: "",
  subscriptionTemplate: "",
  amount: "",
  isActivated: false,
  providerProductId: false,
  ...override,
});

export const subscriptionInitialState = {
  isLoading: false,
  subscriptions: [],
  subscriptionsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  subscriptionsSearch: [],
  subscription: subscriptionModel(),
  errors: {},
};

export default function (state = subscriptionInitialState, action) {
  switch (action.type) {
    case "UPDATE_SUBSCRIPTIONS_FILTERS":
      return {
        ...state,
        subscriptionsFilters: action.payload,
      };
    case "UPDATE_SUBSCRIPTIONS_SEARCH":
      return {
        ...state,
        subscriptionsSearch: action.payload,
      };
    case "UPDATE_SUBSCRIPTIONS":
      return {
        ...state,
        subscriptions: action.payload,
      };

    case "UPDATE_SUBSCRIPTIONS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_SUBSCRIPTIONS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_SUBSCRIPTION":
      return {
        ...state,
        subscription: action.payload,
      };
    default:
      return state;
  }
}
