import React from "react";
import tools from "../../../helpers/tools";
import DocumentItem from "./DocumentItem";

const DocumentList = ({ files, FILE_TYPES, onDocumentDeleted = () => {} }) => {
  return (
    <ul className="list-group">
      {Object.keys(files).map((filekey) => {
        let filesOfType = files[filekey];
        var fileType = tools.findIn(FILE_TYPES, filekey, "id");
        return filesOfType.length ? (
          <li className="" style={{ listStyle: "none" }} key={`filetype${filekey}`}>
            <details>
              <summary className="py-2 tab-item">
                <strong className="text-secondary">
                  {fileType.name} ({filesOfType.length})
                </strong>
              </summary>
              {filesOfType
                .sort((a, b) => (a.uploadedAt > b.uploadedAt ? 1 : -1))
                .map((file) => (
                  <DocumentItem
                    file={file}
                    onDocumentDeleted={(fileId) => onDocumentDeleted(fileType.id, fileId)}
                  />
                ))}
            </details>
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default DocumentList;
