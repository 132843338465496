import { atom } from "jotai";

export const defaultEntityValue = {
  code: "",
  description: "",
  name: "",
};

export const searchListAtom = atom([]);
export const searchFiltersAtom = atom({ filters: { terms: "" }, page: 1 });
export const entityAtom = atom(defaultEntityValue);
export const errorsAtom = atom({});
export const isLoadingAtom = atom(false);
