import { Link } from "react-router-dom";
import UserBox from "../UserBox";

const DesktopSideMenu = ({
  items,
  activeLink,
  subMenu,
  subActiveLink,
  setActiveLink,
  setSubActiveLink,
  setSubMenu,
}) => {
  return (
    <>
      <nav
        id="topsidemenu"
        className={
          "noprint navbar navbar-expand-lg shadow-sm  text-white p-0 d-flex justify-content-start align-items-start flex-column  shadow-sm w-100"
        }
        style={{
          zIndex: "99",
          height: "100%",
        }}
      >
        <h4 className="text-center w-100 py-3">Administration</h4>
        <div
          className="collapse navbar-collapse d-flex flex-row justify-content-center align-items-start w-100 "
          id="navbarSupportedContent"
          style={{
            transition: "400ms",
          }}
        >
          {/* <ul className="navbar-nav me-auto">{getMenu(items)}</ul> */}
          <ul
            className="ps-0 top-menubar  mb-0 d-flex flex-wrap align-items-start flex-column justify-content-start w-100"
            style={{ listStyle: "none", minHeight: 44 }}
          >
            {items.map((it, itk) => {
              var isActive = itk == activeLink;
              var hasChildren = it.path == "#";
              return (
                <li
                  data-priv={it.dataPriv}
                  className="nav-item w-100 text-start"
                  key={`menuitem${itk}`}
                >
                  <Link
                    to={it.path}
                    data-attr={isActive && !hasChildren ? "active" : ""}
                    className={` nav-link text-start btn btn-sm text-white ${
                      it.path == "#" ? "has-children" : ""
                    }`}
                    style={{
                      fontSize: 15,
                    }}
                    onClick={() => {
                      setActiveLink(itk);
                      if (it.path == "#") {
                        setSubMenu(it.items);
                      } else {
                        setSubMenu([]);
                      }
                    }}
                  >
                    <i className={`${it.icon} me-2`} /> {it.name}
                    {/* {it.path == "#" ? (
                    <i className={`fa fa-chevron-down me-1`} />
                  ) : null} */}
                  </Link>
                  {hasChildren && isActive && (
                    <ul
                      className=" bottom-menubar flex-column d-flex flex-wrap  mb-0 ps-0"
                      style={{ listStyle: "none" }}
                    >
                      {it.items.map((sit, sitk) => {
                        var isActive = sitk == subActiveLink;
                        return (
                          <li className="nav-item " key={`submenuitem${sitk}${sit.path}`}>
                            <Link
                              to={sit.path}
                              className={`text-start nav-link btn btn-sm text-white animated fadeInDown ms-3`}
                              style={{
                                animationDelay: sitk * 30 + "ms",
                                animationDuration: "200ms",
                                fontSize: 15,
                              }}
                              onClick={() => setSubActiveLink(sitk)}
                              data-attr={isActive ? "active" : ""}
                            >
                              <i className={`${sit.icon} me-2`} />

                              {sit.name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </nav>
      <div
        className="nav-item"
        style={{
          position: "absolute",
          right: 25,
          top: 25,
          transform: "translateY(-50%)",
          zIndex: "9999",
        }}
      >
        <UserBox />
      </div>
    </>
  );
};

export default DesktopSideMenu;
