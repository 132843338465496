export const managerModel = () => ({
  firstname: "",
  lastname: "",
  role: "",
  email: "",
  password: "",
  isActivated: false,
  shops: [],
});

export const managerInitialState = {
  isLoading: false,
  managers: [],
  managersFilters: {
    filters: { terms: "" },
    page: 1,
  },
  managersSearch: [],
  manager: managerModel(),
  errors: {},
};

export default function (state = managerInitialState, action) {
  switch (action.type) {
    case "UPDATE_MANAGERS_FILTERS":
      return {
        ...state,
        managersFilters: action.payload,
      };
    case "UPDATE_MANAGERS_SEARCH":
      return {
        ...state,
        managersSearch: action.payload,
      };
    case "UPDATE_MANAGERS":
      return {
        ...state,
        managers: action.payload,
      };

    case "UPDATE_MANAGERS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_MANAGERS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_MANAGER":
      return {
        ...state,
        manager: action.payload,
      };
    default:
      return state;
  }
}
