export const subscriptionTemplateModel = () => ({
  name: "",
  code: "",
  description: "",
  documents: [],
});

export const subscriptionTemplateInitialState = {
  isLoading: false,
  subscriptionTemplates: [],
  subscriptionTemplatesFilters: {
    filters: { terms: "" },
    page: 1,
  },
  subscriptionTemplatesSearch: [],
  subscriptionTemplate: subscriptionTemplateModel(),
  errors: {},
};

export default function (state = subscriptionTemplateInitialState, action) {
  switch (action.type) {
    case "UPDATE_SUBSCRIPTION_TEMPLATES_FILTERS":
      return {
        ...state,
        subscriptionTemplatesFilters: action.payload,
      };
    case "UPDATE_SUBSCRIPTION_TEMPLATES_SEARCH":
      return {
        ...state,
        subscriptionTemplatesSearch: action.payload,
      };
    case "UPDATE_SUBSCRIPTION_TEMPLATES":
      return {
        ...state,
        subscriptionTemplates: action.payload,
      };

    case "UPDATE_SUBSCRIPTION_TEMPLATES_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_SUBSCRIPTION_TEMPLATES_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_SUBSCRIPTION_TEMPLATE":
      return {
        ...state,
        subscriptionTemplate: action.payload,
      };
    default:
      return state;
  }
}
