import managerActions from "../../../context/actions/managerActions";
import useStore from "../../../context/useStore";

const useManagers = () => {
  const [state, dispatch] = useStore();
  const managers_actions = new managerActions({
    state,
    dispatch,
    modelName: "managers",
    entityName: "manager",
    apiUrls: {
      search: "user-managers/search",
      getEntity: "user-managers/:id",
      patchEntity: "user-managers/:id",
      deleteEntity: "user-managers/:id",
      postEntity: "user-managers",
    },
    reducerNames: {
      filters: "UPDATE_MANAGERS_FILTERS",
      search: "UPDATE_MANAGERS_SEARCH",
      loading: "UPDATE_MANAGERS_LOADING",
      list: "UPDATE_MANAGERS",
      entity: "UPDATE_MANAGER",
      errors: "UPDATE_MANAGERS_ERRORS",
    },
  });
  const { updateManagersFilters, getManager, deleteManager } = managers_actions;
  const managersState = state.managers;
  const managersFilters = managersState.managersFilters;
  const managersErrors = managersState.errors;
  const { manager, isLoading, managersSearch } = managersState;

  return {
    state,
    dispatch,
    managers_actions,
    managersState,
    managersSearch,
    managersFilters,
    managersErrors,
    manager,
    isLoading,
    deleteManager,
    constants: state.constants.items,
  };
};

export default useManagers;
