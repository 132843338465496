import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import useCustomers from "../../../hooks/useCustomers/useCustomers";

import CustomersFilters from "./CustomersFilters";
import CustomersListTable from "./CustomersListTable";

const CustomersList = (props) => {
  const { customersState, customersSearch, customersFilters, customers_actions, state, dispatch } =
    useCustomers();

  const changePage = (p) => {
    customers_actions.updatePage({
      ...customersFilters,
      page: p,
    });
  };

  return (
    <Page
      title="Clients"
      errors={customersState.errors}
      isLoading={customersState.isLoading}
      {...props}
    >
      <div className="">
        <CustomersFilters />
        <div className="mt-3">
          <CustomersListTable />
        </div>
        {customersSearch.count ? (
          <Pagination
            nbPages={Math.ceil(customersSearch.count / 25)}
            page={customersFilters.page}
            changePage={changePage}
          />
        ) : null}
      </div>
    </Page>
  );
};

export default CustomersList;
