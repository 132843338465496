import React, { useEffect, useState } from "react";
import { subscriptionModel } from "../../../../context/reducers/subscriptionReducers";
import tools from "../../../../helpers/tools";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import Page from "../../../common/layout/Page";
import useShops from "../../../hooks/useShops/useShops";
import useSubscriptions from "../../../hooks/useSubscriptions/useSubscriptions";
import useSubscriptionTemplates from "../../../hooks/useSubscriptionTemplates/useSubscriptionTemplates";

const SubscriptionsDetail = (props) => {
  const { state, dispatch, subscriptions_actions, subscription, subscriptionsErrors, isLoading, constants } =
    useSubscriptions();
  const { subscriptionTemplates_actions } = useSubscriptionTemplates();
  const { shops_actions } = useShops();
  let paramsId = props?.match?.params?.id;
  let shopId = props?.match?.params?.shopId;
  let shopName = state.shops?.shops?.find((s) => s.id == shopId);

  useEffect(() => {
    if (paramsId && paramsId != "create") {
      subscriptions_actions.getEntity(paramsId);
    } else {
      subscriptions_actions.updateEntity(subscriptionModel({ shop: shopId }));
    }
    shops_actions.getList();
    subscriptionTemplates_actions.getList();
  }, []);

  return (
    <Page
      title={`Abonnement ${shopName ? `de ${shopName.name}` : ""}`}
      back={`/shops/${shopId}/subscriptions`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={subscriptions_actions.saveEntity} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Template d'abonnement"
              type="btnList"
              name="subscriptionTemplate"
              dataIndex="id"
              dataLabel="name"
              datas={state.subscriptionTemplates.subscriptionTemplates}
              value={subscription.subscriptionTemplate}
              change={subscriptions_actions.updateEntityAttribute}
              error={subscriptionsErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Prix (en centimes)"
              type="number"
              name="amount"
              value={subscription.amount}
              change={subscriptions_actions.updateEntityAttribute}
              error={subscriptionsErrors}
              suffix={<div className="ps-2"> = {tools.truenumberWithSpaces(subscription.amount / 100)} €</div>}
            />
          </div>
          <div className="col-12 col-md-6 offset-md-6">
            <Control
              label={`${subscription.isActivated ? "Activé" : "Désactivé"}`}
              type="checkbox"
              name="isActivated"
              value={subscription.isActivated}
              change={subscriptions_actions.updateEntityAttribute}
              error={subscriptionsErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default SubscriptionsDetail;
