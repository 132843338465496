import { DateTime } from "luxon";
import React from "react";
import { Link } from "react-router-dom";
import { modalActions } from "../../../../context/actions/modalActions";
import useStore from "../../../../context/useStore";
import tools from "../../../../helpers/tools";
import CustomersDetail from "../../Customers/CustomersDetail/CustomersDetail";
import { useDocumentStore } from "../documentContext/DocumentStore";

const DocumentTitle = () => {
  const [gState, gDispatch] = useStore();
  const modal_actions = modalActions(gState, gDispatch);
  const [document, dispatch] = useDocumentStore();

  const { FILE_TYPES } = gState.constants.items;

  const { chest } = document.dataType;

  const openCustomer = () => {
    modal_actions.updateModal({
      isOpen: true,
      content: (
        <div className="p-4">
          <CustomersDetail match={{ params: { id: chest.userCustomer.id } }} noBack />
        </div>
      ),
    });
  };

  return (
    chest && (
      <div>
        <h4 className={` ${document.isDone ? "" : "text-secondary"}`}>
          <strong>{chest.companyName}</strong> <small>({chest.siret || chest.siren})</small>
        </h4>
        <div>
          <div className="text-capitalize">
            <button className="btn btn-link text-reset p-0" onClick={openCustomer}>
              <i className="fa fa-link me-2" />
              {tools.getFullname(chest.userCustomer)}
            </button>
          </div>
          <div>
            <a className="btn btn-link p-0 text-reset" href={`mailto:${chest.userCustomer.email}`}>
              <i className="fa fa-at me-2" />
              {chest.userCustomer.email}
            </a>
          </div>
          <div>
            <i className="fa fa-file-alt me-2" />
            {tools.findIn(FILE_TYPES, document.dataType.fileType, "id").name}
          </div>
          <div>
            <i className="fa fa-calendar me-2" />A envoyer le{" "}
            {DateTime.fromISO(document.dataType.mustBeUploadedAt)
              .setLocale("FR")
              .toFormat("dd LLLL yyyy")}
          </div>
        </div>
      </div>
    )
  );
};

export default DocumentTitle;
