import chestActions from "../../../context/actions/chestActions";
import useStore from "../../../context/useStore";

const useChests = ({ customerId } = {}) => {
  const [state, dispatch] = useStore();
  const chests_actions = new chestActions({
    state,
    dispatch,
    modelName: "chests",
    entityName: "chest",
    apiUrls: {
      search: "chests/search",
      getEntity: "chests/:id",
      getEntities: `user-customers/${customerId}/chests`,
      patchEntity: "chests/:id",
      postEntity: "chests",
      deleteEntity: "chests/:id",
    },
    reducerNames: {
      filters: "UPDATE_CHESTS_FILTERS",
      search: "UPDATE_CHESTS_SEARCH",
      loading: "UPDATE_CHESTS_LOADING",
      list: "UPDATE_CHESTS",
      entity: "UPDATE_CHEST",
      errors: "UPDATE_CHESTS_ERRORS",
    },
  });
  const { updateChestsFilters, getChest, deleteChest } = chests_actions;
  const chestsState = state.chests;
  const chestsFilters = chestsState.chestsFilters;
  const chestsErrors = chestsState.errors;
  const { chest, isLoading, chestsSearch } = chestsState;

  return {
    state,
    dispatch,
    chests_actions,
    chestsState,
    chestsSearch,
    chestsFilters,
    chestsErrors,
    chest,
    isLoading,
    deleteChest,
    constants: state.constants.items,
  };
};

export default useChests;
