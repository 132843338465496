import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import useManagers from "../../../hooks/useManagers/useManagers";

const ModifyManagerPassword = () => {
  const { state, dispatch, managers_actions, manager, managersErrors, isLoading, constants } = useManagers();

  const [newPassword, setNewPassword] = useState("");

  const handleSubmit = () => {
    managers_actions.modifyManagerPassword(newPassword);
  };

  return (
    <FormContainer onSubmit={handleSubmit} isLoading={isLoading}>
      <div className="row">
        <div className="col-12">
          <h4>Modifier le mot de passe</h4>
        </div>
        <div className="col-12 col-md-6">
          <Control
            label="Mot de passe"
            type="password"
            name="newPassword"
            value={newPassword}
            change={(e) => setNewPassword(e.target.value)}
            error={managersErrors.password ? { newPassword: managersErrors.password } : {}}
          />
        </div>
      </div>
    </FormContainer>
  );
};

export default ModifyManagerPassword;
