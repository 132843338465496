import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import useCustomerSubscriptions from "../../../hooks/useCustomerSubscriptions/useCustomerSubscriptions";
import useCustomers from "../../../hooks/useCustomers/useCustomers";
import CustomerSubscriptionCards from "./CustomerSubscriptionCards";

const CustomerSubscriptionsList = (props) => {
  var { customerId } = props.match.params;
  const { customerSubscriptionsState, customerSubscriptions_actions } = useCustomerSubscriptions({ customerId });
  const { customersState, customers_actions } = useCustomers();

  useEffect(() => {
    customerSubscriptions_actions.getList();
  }, []);

  return (
    <Page errors={customerSubscriptionsState.errors} isLoading={customerSubscriptionsState.isLoading} {...props}>
      <CustomerSubscriptionCards {...props} />
    </Page>
  );
};

export default CustomerSubscriptionsList;
