import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import Loader from "react-spinners/BarLoader";
import colors from "../../../helpers/colors";
import Page from "../../common/layout/Page";
import useDocuments from "../../hooks/useDocuments/useDocuments";
import DocumentCard from "./DocumentCard/DocumentCard";
import documentReducer from "./documentContext/documentReducer";
import { DocumentStoreProvider } from "./documentContext/DocumentStore";
import DocumentFilters from "./DocumentFilters";
import RefreshBtn from "./RefreshBtn";

const Documents = () => {
  const {
    documents_actions,
    documentsFilters,
    documentsErrors,
    isLoading,
    documentsState,
    documentsFiltered,
  } = useDocuments();

  useEffect(() => {
    getDocumentList();
  }, []);

  const getDocumentList = () => {
    if (!isLoading) documents_actions.getList();
  };

  return (
    <Page title="Documents" isLoading={isLoading} titleMargin="mb-3">
      <DocumentFilters />
      <div className="d-flex justify-content-end">
        <RefreshBtn onClick={getDocumentList} isLoading={isLoading} />
      </div>
      <AnimatePresence>
        {documentsFiltered.map((doc, dock) => (
          <motion.div layout key={`docwrap${doc.id}`} className="position-relative">
            <DocumentStoreProvider
              initialState={{ ...doc }}
              reducer={documentReducer}
              key={`documentitem${dock}`}
            >
              <DocumentCard gState={doc} />
            </DocumentStoreProvider>
          </motion.div>
        ))}
      </AnimatePresence>
      {documentsState.documents.length > 0 && (
        <div className="d-flex justify-content-end">
          <RefreshBtn onClick={getDocumentList} isLoading={isLoading} />
        </div>
      )}
    </Page>
  );
};

export default Documents;
