import React from "react";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import useSubscriptionTemplates from "../../../hooks/useSubscriptionTemplates/useSubscriptionTemplates";

const SubscriptionTemplatesFilters = () => {
  const {
    updateFilters,
    subscriptionTemplatesState,
    subscriptionTemplatesFilters,
    subscriptionTemplatesErrors,
    subscriptionTemplates_actions,
  } = useSubscriptionTemplates();
  return (
    <FiltersContainer
      onSubmit={subscriptionTemplates_actions.search}
      filtersState={subscriptionTemplatesState}
      isDataLoaded={subscriptionTemplatesState.subscriptionTemplatesSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={subscriptionTemplatesFilters.filters.terms}
            change={subscriptionTemplates_actions.updateFilters}
            errors={subscriptionTemplatesErrors}
            inputStyle={{ background: "white" }}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default SubscriptionTemplatesFilters;
