import Axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../config";

const dataService = {
  get: (
    url,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.get(API_URL + url)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  post: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  postWithOptions: (
    url,
    postData,
    postOptions,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.post(API_URL + url, postData, postOptions)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  patchWithOptions: (
    url,
    postData,
    postOptions,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.patch(API_URL + url, postData, postOptions)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  postFile: (
    url,
    postData,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {},
    setProgress = () => {}
  ) => {
    Axios.post(API_URL + url, postData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        if (setProgress) {
          setProgress(percentCompleted);
        }
        // do whatever you like with the percentage complete
        // maybe dispatch an action that will update a progress bar or something
      },
    })
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },

  patch: (
    url,
    postData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.patch(API_URL + url, postData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  remove: (
    url,
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.delete(API_URL + url)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  removeNoConfirm: (
    url,
    deleteData = {},
    successCallback = () => {},
    errorCallback = () => {},
    anyCallBack = () => {}
  ) => {
    Axios.delete(API_URL + url, deleteData)
      .then((res) => successCallback(res.data))
      .catch((err) => {
        dataService.errorDisplay(err, errorCallback);
      })
      .then(anyCallBack);
  },
  errorDisplay: (err, errorCallback) => {
    if (
      err.response &&
      err.response.data &&
      Object.keys(err.response.data).length > 0 &&
      typeof err.response.data === "object"
    ) {
      if (err.response.data.other) {
        toast.error(err.response.data.other);
      }
      errorCallback(err.response.data);
    } else {
      if (err.response && err.response.status !== "200") {
        toast.warning(`${err.response.status} : ${err.response.config?.url}`);
      }
      errorCallback({ other: "Une erreur est survenue" });
    }
  },
};

export default dataService;
