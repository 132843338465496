import React from "react";

const StatContainer = ({ title, description, index, ...props }) => {
  return (
    <div
      className="bg-white p-2 rounded w-100 mb-2 animated fadeInUp faster"
      style={{
        animationDelay: `${index * 100}ms`,
      }}
    >
      <h5 className="text-left mb-0 text-secondary ">
        <strong>{title}</strong>
      </h5>
      {description && <div className="font-weight-light py-2">{description}</div>}
      {props.children}
    </div>
  );
};

export default StatContainer;
