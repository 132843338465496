import React from "react";

const RefreshBtn = ({ onClick, isLoading }) => {
  return (
    <button className="btn btn-default" onClick={onClick}>
      <i className={`fa fa-refresh me-2 ${isLoading ? "animated animated rotateOut infinite" : ""}`} />
      Rafraichir
    </button>
  );
};

export default RefreshBtn;
