import React from "react";

const DocumentStore = React.createContext();

export const useDocumentStore = () => React.useContext(DocumentStore);

export const DocumentStoreProvider = ({ children, initialState, reducer }) => {
  const [documentState, dispatch] = React.useReducer(reducer, initialState);

  return <DocumentStore.Provider value={[documentState, dispatch]}>{children}</DocumentStore.Provider>;
};
