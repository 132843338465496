import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";
import useShops from "../../../hooks/useShops/useShops";

const ShopsListTable = () => {
  const { isLoading, shopsSearch, languages, shops_actions, state } = useShops();

  return (
    <Table
      isLoading={isLoading}
      nbResults={shopsSearch.count}
      datas={shopsSearch.data}
      fields={[
        {
          name: "Nom",
          path: "name",
        },
        {
          name: "Code",
          path: "code",
        },
        {
          name: "Description",
          path: "description",
        },
        {
          name: "Abonnements",
          path: "id",
          render: (el) => (
            <Link className="btn btn-secondary btn-sm me-1" to={`/shops/${el}/subscriptions`}>
              Abonnements
            </Link>
          ),
        },

        {
          name: "Actions",
          path: "*",
          render: (el) => {
            return (
              <>
                <Link className="btn btn-secondary btn-sm me-1" to={`/shops/${el.id}`}>
                  Ouvrir
                </Link>
                <button className="btn btn-danger btn-sm" onClick={() => shops_actions.deleteEntity(el.id)}>
                  <i className="fa fa-times  text-white" />
                </button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default ShopsListTable;
