import { generatePath } from "react-router-dom";
import { toast } from "react-toastify";
import dataService from "../helpers/dataService";

class rootActions {
  constructor({ state, dispatch, modelName, entityName, apiUrls, reducerNames }) {
    this.state = state;
    this.dispatch = dispatch;
    this.apiUrls = apiUrls;
    this.modelName = modelName;
    this.entityName = entityName;
    this.reducerNames = reducerNames;

    this.updateIsLoading = this.updateIsLoading.bind(this);
    this.search = this.search.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.getEntity = this.getEntity.bind(this);
    this.updateEntity = this.updateEntity.bind(this);
    this.updateEntityAttribute = this.updateEntityAttribute.bind(this);
    this.getList = this.getList.bind(this);
    this.updateEntities = this.updateEntities.bind(this);
    this.saveEntity = this.saveEntity.bind(this);
  }
  updateErrors(errors) {
    this.dispatch({
      type: this.reducerNames.errors,
      payload: errors,
    });
  }

  updateIsLoading(isLoading) {
    this.dispatch({
      type: this.reducerNames.loading,
      payload: isLoading,
    });
  }

  updateFilters(e) {
    const { name, value } = e.target;
    console.log("name", name, value);
    const filtersObject = this.state[this.modelName][`${this.modelName}Filters`];
    const newFilters = {
      ...filtersObject,
      filters: { ...filtersObject.filters, [name]: value },
    };
    this.dispatch({
      type: this.reducerNames.filters,
      payload: newFilters,
    });
  }

  updatePage(filters) {
    this.dispatch({
      type: this.reducerNames.filters,
      payload: filters,
    });
    this.search(filters);
  }

  search(filters = null) {
    this.updateIsLoading(true);
    dataService.post(
      `${this.apiUrls.search}`,
      filters || this.state[this.modelName][`${this.modelName}Filters`],
      (data) => {
        this.dispatch({
          type: this.reducerNames.search,
          payload: data,
        });
      },
      this.updateErrors,
      () => this.updateIsLoading(false)
    );
  }

  getEntity(entityId) {
    this.updateIsLoading(true);
    const path = generatePath(this.apiUrls.getEntity, { id: entityId });
    dataService.get(
      path,
      this.updateEntity,
      (err) => {
        this.updateErrors(err);
        this.updateEntity({});
      },
      () => this.updateIsLoading(false)
    );
  }

  updateEntity(data) {
    this.dispatch({
      type: this.reducerNames.entity,
      payload: data,
    });
  }
  updateEntityAttribute(event) {
    const entity = this.state[this.modelName][this.entityName];
    const { name, value } = event.target;
    let newEntity = {};

    newEntity = {
      ...entity,
      [name]: value,
    };

    this.updateEntity(newEntity);
  }

  getList() {
    this.updateIsLoading(true);
    dataService.get(
      this.apiUrls.getEntities,
      this.updateEntities,
      (err) => {
        this.updateErrors(err);
        this.updateEntities([]);
      },
      () => this.updateIsLoading(false)
    );
  }

  updateEntities(entities) {
    this.dispatch({
      type: this.reducerNames.list,
      payload: entities,
    });
  }

  saveEntity() {
    const entity = this.state[this.modelName][this.entityName];
    this.updateIsLoading(true);
    if (entity.id) {
      const path = generatePath(this.apiUrls.patchEntity, { id: entity.id });
      dataService.patch(
        path,
        entity,
        (datas) => {
          this.updateEntity(datas);
          this.updateErrors({});
          toast.success("L'entité a bien été mise à jour");
        },
        this.updateErrors,
        () => this.updateIsLoading(false)
      );
    } else {
      dataService.post(
        `${this.apiUrls.postEntity}`,
        entity,
        (datas) => {
          this.updateEntity(datas);
          this.updateErrors({});
          const entities = this.state[this.modelName][this.modelName];
          this.updateEntities([...entities, datas]);
          toast.success("L'entité a bien été créée");
        },
        this.updateErrors,
        () => this.updateIsLoading(false)
      );
    }
  }

  deleteEntity(entityId) {
    if (!window.confirm("Supprimer cette entrée ?")) return false;
    this.updateIsLoading(true);
    const path = generatePath(this.apiUrls.deleteEntity, { id: entityId });
    dataService.remove(
      path,
      (datas) => this.search(),
      this.updateErrors,
      () => this.updateIsLoading(false)
    );
  }
}

export default rootActions;
