import { DateTime } from "luxon";
import React from "react";
import Loader from "../Loader";
import useFile from "../../hooks/useFile/useFile";

const DocumentItem = ({ file, onDocumentDeleted }) => {
  const { downloadFile, isDownloading, deleteFile, isDeleting } = useFile({
    onDeleted: onDocumentDeleted,
  });

  return (
    <div
      className="border p-1 rounded bg-verylight my-1 d-flex align-items-center justify-content-between flex-row"
      key={`file${file.fileId}`}
    >
      <div className="d-flex flex-fill flex-column">
        {file.labels?.map((label, lk) => (
          <div
            className="d-flex flex-row align-items-start justify-content-start"
            key={`label${label.label}${lk}`}
          >
            <i className="fa fa-plus me-2 mt-1 text-info" />
            <div>
              <div className="text-dark">{label.label}</div>
              {label.descriptions.length > 0 && (
                <small className="text-grey">{label.descriptions.join(", ")}</small>
              )}
            </div>
          </div>
        )) || null}
        <div className="mt-1">
          <small>
            <i className="fa fa-calendar me-2" />
            envoyé le {DateTime.fromISO(file.uploadedAt).toFormat("dd.MM.yyyy à HH:mm")}
          </small>
        </div>
      </div>

      <div className="ms-2 d-flex flex-row">
        {isDownloading == file.fileId ? (
          <Loader />
        ) : (
          <button
            className="btn btn-link text-secondary"
            onClick={() =>
              file.fileId ? downloadFile(file.fileId) : window.open(file.externalUrl)
            }
          >
            <i className="fa fa-download" />
          </button>
        )}
        {isDeleting == file.fileId ? (
          <Loader />
        ) : file.fileId ? (
          <button className="btn btn-link" onClick={() => deleteFile(file.fileId)}>
            <i className="fa fa-trash" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default DocumentItem;
