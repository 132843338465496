import React from "react";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import useAllChests from "../../../hooks/useAllChests/useAllChests";

const AllChestsFilters = () => {
  const { updateFilters, allChestsState, allChestsFilters, allChestsErrors, allChests_actions } =
    useAllChests();
  return (
    <FiltersContainer
      onSubmit={allChests_actions.search}
      filtersState={allChestsState}
      isDataLoaded={allChestsState.allChestsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={allChestsFilters.filters.terms}
            change={allChests_actions.updateFilters}
            errors={allChestsErrors}
            inputStyle={{ background: "white" }}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default AllChestsFilters;
