import React, { useEffect, useState } from "react";
import { subscriptionTemplateModel } from "../../../../context/reducers/subscriptionTemplateReducers";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import Page from "../../../common/layout/Page";
import useSubscriptionTemplates from "../../../hooks/useSubscriptionTemplates/useSubscriptionTemplates";

const SubscriptionTemplatesDetail = (props) => {
  const {
    state,
    dispatch,
    subscriptionTemplates_actions,
    subscriptionTemplate,
    subscriptionTemplatesErrors,
    isLoading,
    constants,
  } = useSubscriptionTemplates();

  useEffect(() => {
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      subscriptionTemplates_actions.getEntity(paramsId);
    } else {
      subscriptionTemplates_actions.updateEntity(subscriptionTemplateModel());
    }
  }, []);

  return (
    <Page
      title={subscriptionTemplate.name || "Template d'abonnement"}
      back={`/subscription-templates`}
      isLoading={isLoading}
    >
      <FormContainer onSubmit={subscriptionTemplates_actions.saveEntity} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Nom"
              type="text"
              name="name"
              value={subscriptionTemplate.name}
              change={subscriptionTemplates_actions.updateEntityAttribute}
              error={subscriptionTemplatesErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Code"
              disabled={subscriptionTemplate.id}
              type="text"
              name="code"
              value={subscriptionTemplate.code}
              change={subscriptionTemplates_actions.updateEntityAttribute}
              error={subscriptionTemplatesErrors}
            />
          </div>
          <div className="col-12">
            <Control
              label="Description"
              type="textarea"
              name="description"
              value={subscriptionTemplate.description}
              change={subscriptionTemplates_actions.updateEntityAttribute}
              error={subscriptionTemplatesErrors}
            />
          </div>
        </div>
      </FormContainer>
    </Page>
  );
};

export default SubscriptionTemplatesDetail;
