import React, { useEffect, useState } from "react";
import { managerModel } from "../../../../context/reducers/managerReducers";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import Page from "../../../common/layout/Page";
import useManagers from "../../../hooks/useManagers/useManagers";
import useShops from "../../../hooks/useShops/useShops";
import ModifyManagerPassword from "./ModifyManagerPassword";

const ManagersDetail = (props) => {
  const { state, dispatch, managers_actions, manager, managersErrors, isLoading, constants } =
    useManagers();
  const { shops_actions, shopsState } = useShops();

  const [isModifyPassword, setIsModifyPassword] = useState(false);

  useEffect(() => {
    shops_actions.getList();
    let paramsId = props?.match?.params?.id;
    if (paramsId && paramsId != "create") {
      managers_actions.getEntity(paramsId);
    } else {
      managers_actions.updateEntity(managerModel());
    }
  }, []);

  return (
    <Page title={manager.name?.FR || "Managers"} back={`/managers`} isLoading={isLoading}>
      <FormContainer onSubmit={managers_actions.saveEntity} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Rôle"
              type="btnList"
              name="role"
              value={manager.role}
              change={managers_actions.updateEntityAttribute}
              error={managersErrors}
              datas={constants.ROLES_MANAGER}
              dataIndex="id"
              dataLabel="name"
            />
          </div>
          <div className="col-12 col-md-6">
            {manager.role == "OPERATOR" && (
              <Control
                label="Boutiques"
                type="checkBtnList"
                name="shops"
                value={manager.shops}
                change={managers_actions.updateEntityAttribute}
                error={managersErrors}
                datas={shopsState.shops}
                dataIndex="id"
                dataLabel="name"
              />
            )}
          </div>

          <div className="col-12 col-md-6">
            <Control
              label="Nom"
              type="text"
              name="lastname"
              value={manager.lastname}
              change={managers_actions.updateEntityAttribute}
              error={managersErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Prénom"
              type="text"
              name="firstname"
              value={manager.firstname}
              change={managers_actions.updateEntityAttribute}
              error={managersErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Email"
              type="email"
              name="email"
              value={manager.email}
              change={managers_actions.updateEntityAttribute}
              error={managersErrors}
            />
          </div>
          {!manager.id && (
            <div className="col-12 col-md-6">
              <Control
                label="Mot de passe"
                type="password"
                name="password"
                value={manager.password}
                change={managers_actions.updateEntityAttribute}
                error={managersErrors}
              />
            </div>
          )}

          <div className="col-12 col-md-6">
            <Control
              label="Activation du compte"
              type="checkbox"
              name="isActivated"
              value={manager.isActivated}
              change={managers_actions.updateEntityAttribute}
              error={managersErrors}
            />
          </div>
        </div>
      </FormContainer>
      <div className="row my-2">
        <div className="col-12 col-md-4">
          <button
            className="btn btn-default"
            onClick={() => setIsModifyPassword(!isModifyPassword)}
          >
            {isModifyPassword ? "Annuler" : "Modifier le mot de passe"}
          </button>
        </div>
      </div>
      {isModifyPassword && <ModifyManagerPassword />}
    </Page>
  );
};

export default ManagersDetail;
