import subscriptionActions from "../../../context/actions/subscriptionActions";
import useStore from "../../../context/useStore";

const useSubscriptions = ({ shopId } = {}) => {
  const [state, dispatch] = useStore();
  const subscriptions_actions = new subscriptionActions({
    state,
    dispatch,
    modelName: "subscriptions",
    entityName: "subscription",
    apiUrls: {
      search: "shop-subscriptions/search",
      getEntity: "shop-subscriptions/:id",
      getEntities: `shops/${shopId}/shop-subscriptions`,
      patchEntity: "shop-subscriptions/:id",
      deleteEntity: "shop-subscriptions/:id",
      postEntity: "shop-subscriptions",
    },
    reducerNames: {
      filters: "UPDATE_SUBSCRIPTIONS_FILTERS",
      search: "UPDATE_SUBSCRIPTIONS_SEARCH",
      loading: "UPDATE_SUBSCRIPTIONS_LOADING",
      list: "UPDATE_SUBSCRIPTIONS",
      entity: "UPDATE_SUBSCRIPTION",
      errors: "UPDATE_SUBSCRIPTIONS_ERRORS",
    },
  });
  const { updateSubscriptionsFilters, getSubscription, deleteSubscription } = subscriptions_actions;
  const subscriptionsState = state.subscriptions;
  const subscriptionsFilters = subscriptionsState.subscriptionsFilters;
  const subscriptionsErrors = subscriptionsState.errors;
  const { subscription, subscriptions, isLoading, subscriptionsSearch } = subscriptionsState;

  return {
    state,
    dispatch,
    subscriptions_actions,
    subscriptionsState,
    subscriptionsSearch,
    subscriptionsFilters,
    subscriptionsErrors,
    subscription,
    subscriptions,
    isLoading,
    deleteSubscription,
    constants: state.constants.items,
  };
};

export default useSubscriptions;
