export const chestModel = () => ({});

export const chestInitialState = {
  isLoading: false,
  chests: [],
  chestsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  chestsSearch: [],
  chest: chestModel(),
  errors: {},
};

export default function (state = chestInitialState, action) {
  switch (action.type) {
    case "UPDATE_CHESTS_FILTERS":
      return {
        ...state,
        chestsFilters: action.payload,
      };
    case "UPDATE_CHESTS_SEARCH":
      return {
        ...state,
        chestsSearch: action.payload,
      };
    case "UPDATE_CHESTS":
      return {
        ...state,
        chests: action.payload,
      };

    case "UPDATE_CHESTS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_CHESTS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_CHEST":
      return {
        ...state,
        chest: action.payload,
      };
    default:
      return state;
  }
}
