import { useEffect } from "react";
import colors from "../../../helpers/colors";
import SaveBtn from "../SaveBtn/SaveBtn";

const FiltersContainer = ({ onSubmit, filtersState, filtersErrors, isDataLoaded, ...props }) => {
  useEffect(() => {
    if (onSubmit) {
      onSubmit();
    }
  }, []);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (onSubmit) {
          onSubmit();
        }
      }}
    >
      {props.children}
      {onSubmit && (
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <SaveBtn
              text={isDataLoaded ? "Actualiser" : "Rechercher"}
              typeBtn="submit"
              type="default"
              isSaving={filtersState.isLoading}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default FiltersContainer;
