import customerAddressActions from "../../../context/actions/customerAddressActions";
import useStore from "../../../context/useStore";

const useCustomerAddresss = ({ customerId }) => {
  const [state, dispatch] = useStore();
  const customerAddresss_actions = new customerAddressActions({
    state,
    dispatch,
    modelName: "customerAddresss",
    entityName: "customerAddress",
    apiUrls: {
      getEntity: "user-customerAddresss",
      patchEntity: "customer-addresses",
      postEntity: "customer-addresses",
      getEntities: `user-customers/${customerId}/customer-addresses/BILLING`,
    },
    reducerNames: {
      filters: "UPDATE_CUSTOMER_ADDRESSS_FILTERS",
      search: "UPDATE_CUSTOMER_ADDRESSS_SEARCH",
      loading: "UPDATE_CUSTOMER_ADDRESSS_LOADING",
      list: "UPDATE_CUSTOMER_ADDRESSS",
      entity: "UPDATE_CUSTOMER_ADDRESS",
      errors: "UPDATE_CUSTOMER_ADDRESSS_ERRORS",
    },
  });
  const { updateCustomerAddresssFilters, getCustomerAddress, deleteCustomerAddress } = customerAddresss_actions;
  const customerAddresssState = state.customerAddresss;
  const customerAddresssFilters = customerAddresssState.customerAddresssFilters;
  const customerAddresssErrors = customerAddresssState.errors;
  const { customerAddress, customerAddresss, isLoading, customerAddresssSearch } = customerAddresssState;

  return {
    state,
    dispatch,
    customerAddresss_actions,
    customerAddresssState,
    customerAddresssSearch,
    customerAddresssFilters,
    customerAddresssErrors,
    customerAddress,
    customerAddresss,
    isLoading,
    deleteCustomerAddress,
    constants: state.constants.items,
  };
};

export default useCustomerAddresss;
