import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Loader from "../Loader";
import { BarLoader } from "react-spinners";
import colors from "../../../helpers/colors";
import useTopMenu from "./TopMenu/useTopMenu";

const Page = ({
  container = "",
  className = "",
  containerClassname = "",
  title,
  children,
  errors = null,
  back = null,
  action = null,
  style = {},
  margin = " ",
  titleMargin = "",
  notAnimated = false,
  isLoading = false,
  contentStyle = {},
  contentClassname = "",
  isInit = true,
  printOrientation = "portrait",
}) => {
  const location = useLocation();
  const { currentPage } = useTopMenu({ location });

  const pageVariants = {
    initial: {
      opacity: 0,
      x: 50,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: -50,
    },
  };

  return (
    <motion.div
      className={className}
      style={style}
      initial="initial"
      exit="out"
      animate="in"
      variants={pageVariants}
      transition={{ duration: 0.3 }}
    >
      <div
        className={" custom-page-container " + container + " " + margin + " " + containerClassname}
      >
        {title && (
          <div className={" d-between   " + titleMargin}>
            <div className="d-flex align-items-center w-100">
              <div className="d-center align-items-center">
                {back && (
                  <Link
                    to={back}
                    className="btn me-2 shadow-none text-grey"
                    style={{ padding: "15px 18px" }}
                  >
                    <i className="fa fa-arrow-left"></i>
                  </Link>
                )}

                <h1 className="d-center flex-row mb-0 text-uppercase" style={{ fontSize: 28 }}>
                  {!back && currentPage?.icon && (
                    <i
                      className={`d-block ${
                        currentPage ? `animated fadeIn ${currentPage.icon}` : ``
                      }`}
                      style={{
                        fontSize: 24,
                        width: 40,
                        maxWidth: 40,
                      }}
                    />
                  )}{" "}
                  <strong className="pt-1">{title}</strong>
                </h1>
              </div>
              {action && (
                <Link to={action.to} className="btn btn-primary d-center ms-2 btn-sm " style={{}}>
                  <i className="fa fa-plus p-1" />
                </Link>
              )}
            </div>

            {/* <button
              title="Imprimer la page"
              className="btn btn-default noprint"
              onClick={() => tools.print(printOrientation)}
            >
              <i className="fa fa-print" />
            </button> */}
          </div>
        )}
        {errors && errors.other && (
          <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: errors.other }} />
        )}
        <div className={contentClassname} style={contentStyle}>
          {isInit ? children : <Loader />}
        </div>
      </div>
      {isLoading && (
        <div
          className="animated fadeIn slow"
          style={{
            position: "fixed",
            bottom: 0,
            right: 0,
            zIndex: "9999",
            width: "100%",
            boxShadow: "0px -3px 16px -3px " + colors.primary,
          }}
        >
          <BarLoader height={8} width={"100%"} color={colors.primary} />
        </div>
      )}
    </motion.div>
  );
};

export default Page;
