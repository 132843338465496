import customerSubscriptionActions from "../../../context/actions/customerSubscriptionActions";
import useStore from "../../../context/useStore";

const useChests = ({ customerId } = {}) => {
  const [state, dispatch] = useStore();
  const customerSubscriptions_actions = new customerSubscriptionActions({
    state,
    dispatch,
    modelName: "customerSubscriptions",
    entityName: "customerSubscription",
    apiUrls: {
      search: "customerSubscriptions/search",
      getEntity: "customerSubscriptions/:id",
      getEntities: `user-customers/${customerId}/subscriptions`,
      patchEntity: "customerSubscriptions/:id",
      postEntity: "customerSubscriptions",
      deleteEntity: "customerSubscriptions/:id",
    },
    reducerNames: {
      filters: "UPDATE_CUSTOMER_SUBSCRIPTIONS_FILTERS",
      search: "UPDATE_CUSTOMER_SUBSCRIPTIONS_SEARCH",
      loading: "UPDATE_CUSTOMER_SUBSCRIPTIONS_LOADING",
      list: "UPDATE_CUSTOMER_SUBSCRIPTIONS",
      entity: "UPDATE_CUSTOMER_SUBSCRIPTION",
      errors: "UPDATE_CUSTOMER_SUBSCRIPTIONS_ERRORS",
    },
  });
  const { updateChestsFilters, getChest, deleteChest } = customerSubscriptions_actions;
  const customerSubscriptionsState = state.customerSubscriptions;
  const customerSubscriptionsFilters = customerSubscriptionsState.customerSubscriptionsFilters;
  const customerSubscriptionsErrors = customerSubscriptionsState.errors;
  const { customerSubscription, isLoading, customerSubscriptionsSearch } = customerSubscriptionsState;

  return {
    state,
    dispatch,
    customerSubscriptions_actions,
    customerSubscriptionsState,
    customerSubscriptionsSearch,
    customerSubscriptionsFilters,
    customerSubscriptionsErrors,
    customerSubscription,
    isLoading,
    deleteChest,
    constants: state.constants.items,
  };
};

export default useChests;
