export const shopModel = () => ({
  code: "",
  description: "",
  name: "",
});

export const shopInitialState = {
  isLoading: false,
  shops: [],
  shopsFilters: {
    filters: { terms: "" },
    page: 1,
  },
  shopsSearch: [],
  shop: shopModel(),
  errors: {},
};

export default function (state = shopInitialState, action) {
  switch (action.type) {
    case "UPDATE_SHOPS_FILTERS":
      return {
        ...state,
        shopsFilters: action.payload,
      };
    case "UPDATE_SHOPS_SEARCH":
      return {
        ...state,
        shopsSearch: action.payload,
      };
    case "UPDATE_SHOPS":
      return {
        ...state,
        shops: action.payload,
      };

    case "UPDATE_SHOPS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_SHOPS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_SHOP":
      return {
        ...state,
        shop: action.payload,
      };
    default:
      return state;
  }
}
