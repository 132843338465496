export const customerAddressModel = () => ({
  name: "",
  address1: "",
  address2: "",
  postalCode: "",
  city: "",
});

export const addressInitialState = {
  isLoading: false,
  customerAddresss: [],
  customerAddress: customerAddressModel(),
  errors: {},
};

export default function (state = addressInitialState, action) {
  switch (action.type) {
    case "UPDATE_CUSTOMER_ADDRESSS":
      return {
        ...state,
        customerAddresss: action.payload,
      };

    case "UPDATE_CUSTOMER_ADDRESSS_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };

    case "UPDATE_CUSTOMER_ADDRESSS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
        errors: action.payload ? {} : state.errors,
      };
    case "UPDATE_CUSTOMER_ADDRESS":
      return {
        ...state,
        customerAddress: action.payload,
      };
    default:
      return state;
  }
}
