const initialState = {
  isDataLoaded: false,
  isDocsLoaded: false,
  isLoading: false,
};

export const initDocument = (payload) => ({
  type: "INIT_DOCUMENT",
  payload,
});

export const setIsLoading = (payload) => ({
  type: "IS_LOADING",
  payload,
});

export const updateDocument = (payload) => ({
  type: "UPDATE_DOCUMENT",
  payload,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case "INIT_DOCUMENT":
      return action.payload;
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "UPDATE_DOCUMENT":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
