import React from "react";

const CustomerSubscriptionStore = React.createContext();

export const useCustomerSubscriptionStore = () => React.useContext(CustomerSubscriptionStore);

export const CustomerSubscriptionStoreProvider = ({ children, initialState, reducer }) => {
  const [customerSubscriptionState, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CustomerSubscriptionStore.Provider value={[customerSubscriptionState, dispatch]}>
      {children}
    </CustomerSubscriptionStore.Provider>
  );
};
