import React, { useState } from "react";
import { toast } from "react-toastify";
import dataService from "../../../../../helpers/dataService";
import { useChestStore } from "../chestContext/ChestStore";

const KbisDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [chest, dispatch] = useChestStore();

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  const generateNewKbis = () => {
    setIsOpen(false);
    if (isLoading) return false;
    setIsLoading(true);
    dataService.post(
      `files/KBIS/reload`,
      { chestId: chest.chest.id },
      () => {
        toast.success("Un KBIS a été ajouté à la file d'attente pour ce coffre");
      },
      (err) => {},
      () => setIsLoading(false)
    );
  };

  return (
    <div className="position-relative" onBlur={handleBlur}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="p-2 rounded-circle btn btn-outline-primary d-center border border-primary ml-2"
        style={{
          width: 25,
          height: 25,
        }}
      >
        <i className="fa fa-ellipsis-h" />
      </button>
      {isOpen && (
        <div
          className="arrow-box-up shadow rounded animated fadeIn faster"
          style={{
            position: "absolute",
            top: "150%",
            left: -11,
            width: 200,
            zIndex: "8",
          }}
        >
          <button className="btn btn-secondary w-100 " onClick={generateNewKbis}>
            Ajouter un KBIS à la file d'attente pour ce coffre
          </button>
        </div>
      )}
    </div>
  );
};

export default KbisDropdown;
