import { DateTime } from "luxon";
import React, { useState } from "react";
import Control from "../../../common/Controls/Control";
import FormContainer from "../../../common/FormContainer/FormContainer";
import Page from "../../../common/layout/Page";
import useCustomers from "../../../hooks/useCustomers/useCustomers";
import CustomerAddresss from "./CustomerAddresss./CustomerAddresss";
import ModifyCustomerPassword from "./ModifyCustomerPassword";

const CustomerInformationsForm = (props) => {
  const { state, dispatch, customers_actions, customer, customersErrors, isLoading, constants } = useCustomers();
  let { customerId } = props?.match?.params;
  const [isModifyPassword, setIsModifyPassword] = useState(false);

  return (
    <Page isLoading={isLoading}>
      <FormContainer onSubmit={customers_actions.saveEntity} isLoading={isLoading}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Control
              label="Nom"
              type="text"
              name="lastname"
              value={customer.lastname}
              change={customers_actions.updateEntityAttribute}
              error={customersErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Prénom"
              type="text"
              name="firstname"
              value={customer.firstname}
              change={customers_actions.updateEntityAttribute}
              error={customersErrors}
            />
          </div>
          <div className="col-12 col-md-6">
            <Control
              label="Email"
              type="email"
              name="email"
              value={customer.email}
              change={customers_actions.updateEntityAttribute}
              error={customersErrors}
            />
          </div>
          {!customer.id && (
            <div className="col-12 col-md-6">
              <Control
                label="Mot de passe"
                type="password"
                name="password"
                value={customer.password}
                change={customers_actions.updateEntityAttribute}
                error={customersErrors}
              />
            </div>
          )}

          <div className="col-12 col-md-6">
            <Control
              label={`${customer.isActivated ? "Email vérifié" : "Email non vérifié"}`}
              type="checkbox"
              name="isActivated"
              value={customer.isActivated}
              change={customers_actions.updateEntityAttribute}
              error={customersErrors}
            />
            <div>
              {customer.termsOfService?.isAccepted ? "CGU acceptées" : "CGU refusées"} le{" "}
              {DateTime.fromISO(customer.termsOfService?.acceptedAt).toFormat("dd.MM.yyyy hh:mm")}
            </div>
            <div>Compte créé le {DateTime.fromISO(customer.createdAt).toFormat("dd.MM.yyyy hh:mm")}</div>
            <div>Mis à jour le {DateTime.fromISO(customer.updatedAt).toFormat("dd.MM.yyyy hh:mm")}</div>
          </div>
          <div className="col-12 mt-3">
            <h4>Adresses de facturation</h4>
            <CustomerAddresss customerId={customerId} />
          </div>
        </div>
      </FormContainer>
      <div className="row my-2">
        <div className="col-12 col-md-4">
          <button className="btn btn-default" onClick={() => setIsModifyPassword(!isModifyPassword)}>
            {isModifyPassword ? "Annuler" : "Modifier le mot de passe"}
          </button>
        </div>
      </div>
      {isModifyPassword && <ModifyCustomerPassword />}
    </Page>
  );
};

export default CustomerInformationsForm;
