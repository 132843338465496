import React, { useEffect } from "react";
import useCustomerAddresss from "../../../../hooks/useCustomerAddresss/useCustomerAddresss";
import AddressItem from "./AddressItem";

const CustomerAddresss = ({ customerId }) => {
  const { customerAddresss_actions, customerAddresss, customerAddress, state, dispatch } = useCustomerAddresss({
    customerId,
  });

  useEffect(() => {
    customerAddresss_actions.getList();
  }, []);

  return (
    <ul className="list-group">
      {customerAddresss.map((address) => (
        <AddressItem key={`addr${address.id}`} address={address} />
      ))}
    </ul>
  );
};

export default CustomerAddresss;
