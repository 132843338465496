import React from "react";
import { Link } from "react-router-dom";
import tools from "../../../../helpers/tools";
import Table from "../../../common/Table/Table";
import useManagers from "../../../hooks/useManagers/useManagers";

const ManagersListTable = () => {
    const { isLoading, managersSearch, languages, managers_actions, state } = useManagers();

    return (
        <Table
            isLoading={isLoading}
            nbResults={managersSearch.count}
            datas={managersSearch.data}
            fields={[
                {
                    name: "Nom",
                    path: "*",
                    render: (el) => tools.getFullname(el),
                },
                {
                    name: "Email",
                    path: "email",
                },
                {
                    name: "Rôle",
                    path: "role",
                },

                {
                    name: "Actions",
                    path: "*",
                    render: (el) => {
                        return (
                            <>
                                <Link className="btn btn-secondary btn-sm me-1" to={`/managers/${el.id}`}>
                                    Ouvrir
                                </Link>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => managers_actions.deleteEntity(el.id)}
                                >
                                    <i className="fa fa-times  text-white" />
                                </button>
                            </>
                        );
                    },
                },
            ]}
        />
    );
};

export default ManagersListTable;
