import { AnimatePresence } from "framer-motion";
import { Switch, useLocation } from "react-router-dom";
import Dashboard from "../../component/pages/Dashboard/Dashboard";
import PrivateRoute from "../../component/common/PrivateRoute";
import ManagersList from "../../component/pages/Managers/ManagersList/ManagersList";
import ManagersDetail from "../../component/pages/Managers/ManagersDetail/ManagersDetail";
import ShopsList from "../../component/pages/Shops/ShopsList/ShopsList";
import ShopsListTest from "../../component/pages/Shops-test/ShopsList/ShopsList";
import ShopsDetail from "../../component/pages/Shops/ShopsDetail/ShopsDetail";
import ShopsDetailTest from "../../component/pages/Shops-test/ShopsDetail/ShopsDetail";
import SubscriptionTemplatesList from "../../component/pages/SubscriptionTemplates/SubscriptionTemplatesList/SubscriptionTemplatesList";
import SubscriptionTemplatesDetail from "../../component/pages/SubscriptionTemplates/SubscriptionTemplatesDetail/SubscriptionTemplatesDetail";
import SubscriptionsList from "../../component/pages/Subscriptions/SubscriptionsList/SubscriptionsList";
import SubscriptionsDetail from "../../component/pages/Subscriptions/SubscriptionsDetail/SubscriptionsDetail";
import CustomersList from "../../component/pages/Customers/CustomersList/CustomersList";
import CustomersDetail from "../../component/pages/Customers/CustomersDetail/CustomersDetail";
import ChestsList from "../../component/pages/Chests/ChestsList/ChestsList";
import Documents from "../../component/pages/Documents/Documents";
import Crons from "../../component/pages/Crons/Crons";
import DownloadInvoice from "../../component/pages/DownloadInvoice/DownloadInvoice";
import AllChestsList from "../../component/pages/AllChests/ManagersList/AllChestsList";

const PrivateRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname}>
        <PrivateRoute path="/" exact component={Dashboard} />
        <PrivateRoute path="/managers" exact component={ManagersList} />
        <PrivateRoute path="/managers/:id" exact component={ManagersDetail} />
        <PrivateRoute path="/customers" exact component={CustomersList} />
        <PrivateRoute path="/customers/:id" exact component={CustomersDetail} />
        <PrivateRoute path="/customers/:id/chests" exact component={CustomersDetail} />
        <PrivateRoute path="/shops-test" exact component={ShopsListTest} />
        <PrivateRoute path="/shops" exact component={ShopsList} />
        <PrivateRoute path="/shops/:id" exact component={ShopsDetail} />
        <PrivateRoute path="/customers/:customerId/chests" exact component={ChestsList} />
        <PrivateRoute path="/shops-test/:id" exact component={ShopsDetailTest} />
        <PrivateRoute path="/subscription-templates" exact component={SubscriptionTemplatesList} />
        <PrivateRoute
          path="/subscription-templates/:id"
          exact
          component={SubscriptionTemplatesDetail}
        />
        <PrivateRoute path="/shops/:shopId/subscriptions" exact component={SubscriptionsList} />
        <PrivateRoute
          path="/shops/:shopId/subscriptions/:id"
          exact
          component={SubscriptionsDetail}
        />
        <PrivateRoute path="/documents" exact component={Documents} />
        <PrivateRoute path="/crons" exact component={Crons} />
        <PrivateRoute path="/invoice/:id/download" exact component={DownloadInvoice} />
        <PrivateRoute path="/all-chests" exact component={AllChestsList} />
      </Switch>
    </AnimatePresence>
  );
};

export default PrivateRoutes;
