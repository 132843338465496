import { Provider } from "jotai";
import { DateTime } from "luxon";
import React from "react";
import useStore from "../../../../../context/useStore";
import tools from "../../../../../helpers/tools";
import AddressItem from "../../CustomersDetail/CustomerAddresss./AddressItem";
import Invoices from "../../CustomersDetail/Invoices/Invoices";
import { useCustomerSubscriptionStore } from "../customerSubscriptionContext/CustomerSubscriptionStore";
import PaymentMethods from "./PaymentMethods/PaymentMethods";

const CustomerSubscriptionCard = (props) => {
  const [state] = useStore();
  const { SUBSCRIPTION_STATUS } = state.constants.items;
  const [customerSubscription, dispatch] = useCustomerSubscriptionStore();

  const totalAmount = customerSubscription.chests.reduce(
    (a, b) => {
      return {
        shopSubscription: { amount: a.shopSubscription.amount + b.shopSubscription.amount },
      };
    },
    { shopSubscription: { amount: 0 } }
  );

  var subStatus = tools.findIn(SUBSCRIPTION_STATUS, customerSubscription.status, "id");

  return (
    <div className="row bg-white mx-0 text-grey border rounded mb-3 shadow-sm py-4 px-3">
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center flex-row flex-wrap">
          <h4 className="d-center justify-content-start">
            <span className="me-2">Abonnement</span>
            <div className="text-white badge bg-primary">
              {tools.truenumberWithSpaces(totalAmount.shopSubscription.amount / 100)}{" "}
              <small>€/mois</small>
            </div>
            <div className={`ms-2 badge bg-${subStatus.color}`}>
              <i className={`fa fa-${subStatus.icon} me-2`} />
              {subStatus.name}
            </div>
          </h4>
          <div>
            <div>
              Créé le : {DateTime.fromISO(customerSubscription.createdAt).toFormat("dd.MM.yyyy")}
            </div>
            <div>
              Echéance : {DateTime.fromISO(customerSubscription.echeance).toFormat("dd.MM.yyyy")}
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6 mt-2">
        <div>
          <div>
            <strong className="text-dark">Coffres</strong>
          </div>
          {customerSubscription.chests.map((chest) => (
            <li key={`subchest${customerSubscription.id}${chest.chest.id}`}>
              <button className="btn btn-link px-0" onClick={props.goToChestTab}>
                {chest.chest.companyName}
              </button>
              <span className="text-dark ms-2"> SIREN : {chest.chest.siren}</span>
            </li>
          ))}
        </div>
        <div>
          <div>
            <strong className="text-dark">Adresse de facturation</strong>
          </div>
          <ul className="list-group">
            <AddressItem address={customerSubscription.billingAddress} />
          </ul>
        </div>
      </div>
      <div className="col-12 col-md-6 mt-2">
        <div>
          <div>
            <strong className="text-dark">Méthode de paiement</strong>
          </div>
          <PaymentMethods paymentMethodId={customerSubscription.providerData.paymentMethodId} />
        </div>
        <div>
          <Provider>
            <Invoices subscriptionId={customerSubscription.id} />
          </Provider>
        </div>
      </div>
    </div>
  );
};

export default CustomerSubscriptionCard;
