import axios from "axios";
import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { API_URL } from "../../../../../config";
import useStore from "../../../../../context/useStore";

const useDocumentUploader = ({ itemId = null, onUploaded = () => {} }) => {
  const [state, dispatch] = useStore();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setIsUploaded(false);
  }, [itemId]);

  const onDrop = useCallback((acceptedFiles) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (acceptedFiles.length > 0 && allowedTypes.indexOf(acceptedFiles[0].type) != -1) {
      setFile(acceptedFiles[0]);
    } else {
      alert("Le fichier choisi est incorrect. Les formats acceptés sont jpeg, png et pdf.");
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = new FormData();
    data.append("file", file);

    setIsUploading(true);
    axios
      .patch(`${API_URL}queues/${itemId}/process`, data, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
          if (percentCompleted >= progress + 25) {
            setProgress(percentCompleted);
          }
        },
      })
      .then((res) => {
        toast.success("Document enregistré");
        setIsUploaded(true);
        setFile(null);
        onUploaded(res.data.id);
      })
      .catch((err) => {
        toast.error("Une erreur est survenue, veuillez réessayer");
        if (err.response && err.response.data) {
          setErrors(err.response.data);
        }
      })
      .then(() => {
        setIsUploading(false);
      });
  };

  const clearFile = () => {
    setFile(null);
  };

  return {
    file,
    setFile,
    getRootProps,
    getInputProps,
    isDragActive,
    handleSubmit,
    errors,
    isUploading,
    FILE_TYPES: state.constants.items.FILE_TYPES,
    clearFile,
    progress,
    isUploaded,
    setIsUploaded,
  };
};

export default useDocumentUploader;
