import { atom, useAtom } from "jotai";
import { useState } from "react";
import dataService from "../../../../../helpers/dataService";

const invoicesAtom = atom([]);

const useInvoices = (subscriptionId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [invoices, setInvoices] = useAtom(invoicesAtom);

  const getInvoices = () => {
    dataService.get(`subscriptions/${subscriptionId}/invoices`, (datas) => {
      setInvoices(datas);
    });
  };

  return {
    invoices,
    isLoading,
    getInvoices,
  };
};

export default useInvoices;
