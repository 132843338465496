import authReducer from "./reducers/authReducers";
import errorReducer from "./reducers/errorReducers";
import constantReducer from "./reducers/constantReducers";
import modalReducer from "./reducers/modalReducers";
import rightPanelReducer from "./reducers/rightPanelReducers";
import leftPanelReducer from "./reducers/leftPanelReducers";
import managerReducers from "./reducers/managerReducers";
import shopReducers from "./reducers/shopReducers";
import subscriptionTemplateReducers from "./reducers/subscriptionTemplateReducers";
import subscriptionReducers from "./reducers/subscriptionReducers";
import customerReducers from "./reducers/customerReducers";
import customerAddressReducer from "./reducers/customerAddressReducer";
import chestReducers from "./reducers/chestReducers";
import customerSubscriptionReducers from "./reducers/customerSubscriptionReducers";
import documentReducers from "./reducers/documentReducers";
import allChestReducers from "./reducers/allChestReducers";

function combineReducers(reducers) {
  // First get an array with all the keys of the reducers (the reducer names)
  const reducerKeys = Object.keys(reducers);

  return function combination(state = {}, action) {
    // This is the object we are going to return.
    const nextState = {};

    // Loop through all the reducer keys
    for (let i = 0; i < reducerKeys.length; i++) {
      // Get the current key name
      const key = reducerKeys[i];
      // Get the current reducer
      const reducer = reducers[key];
      // Get the the previous state
      const previousStateForKey = state[key];
      // Get the next state by running the reducer
      const nextStateForKey = reducer(previousStateForKey, action);
      // Update the new state for the current reducer
      nextState[key] = nextStateForKey;
    }
    return nextState;
  };
}

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  constants: constantReducer,
  modal: modalReducer,
  rightPanel: rightPanelReducer,
  leftPanel: leftPanelReducer,
  managers: managerReducers,
  shops: shopReducers,
  subscriptionTemplates: subscriptionTemplateReducers,
  subscriptions: subscriptionReducers,
  customers: customerReducers,
  customerAddresss: customerAddressReducer,
  chests: chestReducers,
  customerSubscriptions: customerSubscriptionReducers,
  documents: documentReducers,
  allChests: allChestReducers,
});
