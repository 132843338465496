import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import Pagination from "../../../common/Pagination/Pagination";
import useManagers from "../../../hooks/useManagers/useManagers";

import ManagersFilters from "./ManagersFilters";
import ManagersListTable from "./ManagersListTable";

const ManagersList = (props) => {
  const { managersState, managersSearch, managersFilters, managers_actions, state, dispatch } = useManagers();

  return (
    <Page
      title="Managers"
      errors={managersState.errors}
      isLoading={managersState.isLoading}
      action={{
        to: "managers/create",
        text: "Créer un manager",
      }}
      {...props}
    >
      <div className="">
        <ManagersFilters />
        <div className="mt-3">
          <ManagersListTable />
        </div>
        {managersSearch.count ? (
          <Pagination
            nbPages={Math.ceil(managersSearch.count / 25)}
            page={managersFilters.page}
            changePage={(p) =>
              managers_actions.updatePage({
                ...managersFilters,
                page: p,
              })
            }
          />
        ) : null}
      </div>
    </Page>
  );
};

export default ManagersList;
