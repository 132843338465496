import allChestActions from "../../../context/actions/allChestActions";
import useStore from "../../../context/useStore";

const useAllChests = () => {
  const [state, dispatch] = useStore();
  const allChests_actions = new allChestActions({
    state,
    dispatch,
    modelName: "allChests",
    entityName: "allChest",
    apiUrls: {
      search: "chests/search",
      getEntity: "allChests/:id",
      patchEntity: "allChests/:id",
      deleteEntity: "allChests/:id",
      postEntity: "allChests",
    },
    reducerNames: {
      filters: "UPDATE_ALLCHESTS_FILTERS",
      search: "UPDATE_ALLCHESTS_SEARCH",
      loading: "UPDATE_ALLCHESTS_LOADING",
      list: "UPDATE_ALLCHESTS",
      entity: "UPDATE_ALLCHEST",
      errors: "UPDATE_ALLCHESTS_ERRORS",
    },
  });
  const { updateAllChestsFilters, getAllChest, deleteAllChest } = allChests_actions;
  const allChestsState = state.allChests;
  const allChestsFilters = allChestsState.allChestsFilters;
  const allChestsErrors = allChestsState.errors;
  const { allChest, isLoading, allChestsSearch } = allChestsState;

  return {
    state,
    dispatch,
    allChests_actions,
    allChestsState,
    allChestsSearch,
    allChestsFilters,
    allChestsErrors,
    allChest,
    isLoading,
    deleteAllChest,
    constants: state.constants.items,
  };
};

export default useAllChests;
