const initialState = {
  isDataLoaded: false,
  isDocsLoaded: false,
  extendedInfos: {},
  isLoading: false,
};

export const initCustomerSubscription = (payload) => ({
  type: "INIT_CUSTOMER_SUBSCRIPTION",
  payload,
});

export const setIsLoading = (payload) => ({
  type: "IS_LOADING",
  payload,
});
export const populateCustomerSubscriptionDocuments = (payload) => ({
  type: "POPULATE_CUSTOMER_SUBSCRIPTION_DOCUMENTS",
  payload,
});

export const updateFiles = (payload) => ({
  type: "UPDATE_FILES",
  payload,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case "INIT_CUSTOMER_SUBSCRIPTION":
      return action.payload;
    case "IS_LOADING":
      return { ...state, isLoading: action.payload };
    case "UPDATE_FILES":
      return { ...state, files: action.payload };
    default:
      return state;
  }
}
