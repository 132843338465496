import React, { useEffect } from "react";
import usePaymentMethods from "../../../../../hooks/usePaymentMethods/usePaymentMethods";
import PaymentMethodButton from "./PaymentMethodButton";

const PaymentMethods = ({ paymentMethodId = null }) => {
  const { getPaymentMethod, paymentMethods, paymentMethod } = usePaymentMethods();

  useEffect(() => {
    if (paymentMethodId) {
      getPaymentMethod(paymentMethodId);
    }
  }, []);

  return (
    <div className="d-flex w-100">
      <PaymentMethodButton data={paymentMethod} isActive />
    </div>
  );
};

export default PaymentMethods;
