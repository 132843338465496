import { atomWithStorage } from "jotai/utils";

export const atoms = {
  userCustomers: {
    url: "statistics/user-customers",
    atom: atomWithStorage("userCustomers", false),
  },
  chests: {
    url: "statistics/chests",
    atom: atomWithStorage("chests", false),
  },
  files: {
    url: "statistics/files",
    atom: atomWithStorage("files", false),
  },
  links: {
    url: "statistics/links",
    atom: atomWithStorage("links", false),
  },
  canceledSubscriptions: {
    url: "statistics/canceled-subscriptions",
    atom: atomWithStorage("canceledSubscriptions", false),
  },
};
