import logo from "./logo.svg";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import useAuth from "./component/hooks/useAuth/useAuth";
import PublicRoutes from "./routes/PublicRoutes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes/PrivateRoutes";
import Modal from "./component/overlays/Modal/Modal";
import RightPanel from "./component/overlays/RightPanel/RightPanel";
import LeftPanel from "./component/overlays/LeftPanel/LeftPanel";
import TopMenu from "./component/common/layout/TopMenu/TopMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useLayout from "./component/hooks/useLayout/useLayout";
import RoleBoundary from "./RoleBoundary/RoleBoundary";

function App() {
  const { constantsLoaded, isAuth } = useAuth();

  const { layout } = useLayout();

  return (
    <Router>
      <RoleBoundary />
      <ToastContainer position="top-right" autoClose={5000} />
      <div className={layout.wrapper.className} style={layout.wrapper.style}>
        {isAuth && (
          <span className={layout.menuContainer.className} style={layout.menuContainer.style}>
            <TopMenu />
          </span>
        )}
        <div id="content" className={layout.content.className} style={layout.content.style}>
          <PublicRoutes />
          <PrivateRoutes />
        </div>
      </div>
      <Modal />
      <RightPanel />
      <LeftPanel />
    </Router>
  );
}

export default App;
