import { useAtom } from "jotai";
import { layoutAtom } from "../../../helpers/layoutAtoms";

const useLayout = () => {
  const [layout, setLayout] = useAtom(layoutAtom);

  var layouts = {
    print: {
      wrapper: {
        className: "wraper",
      },
      menuContainer: { style: { display: "none" } },
      content: {
        className: "mx-auto",
        style: {
          padding: 0,
          paddingTop: 0,
          maxWidth: 1200,
        },
      },
    },
    layout1: {
      wrapper: {
        className: "wraper",
      },
      menuContainer: {},
      content: {
        className: "mx-auto",
        style: {
          padding: "0 30px",
          paddingTop: 130,
          maxWidth: 1200,
        },
      },
    },

    layout2: {
      wrapper: {
        className: "wrapper  py-0 w-100",
        style: {
          display: "flex",
          alignItems: "stretch",
        },
      },
      menuContainer: {
        className: "bg-secondary",
        style: { width: "20%" },
      },
      content: {
        className: "mx-auto",
        style: {
          width: "80%",
          padding: "30px",
        },
      },
    },
  };

  return { layout: layouts[layout], setLayout, selectedLayout: layout };
};

export default useLayout;
