import { useAtom } from "jotai";
import ShopModel from "../model/model";
import { isLoadingAtom, entityAtom, errorsAtom, searchFiltersAtom, searchListAtom } from "./shopAtoms";

const useShopAtoms = () => {
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom);
  const [entity, setEntity] = useAtom(entityAtom);
  const [errors, setErrors] = useAtom(errorsAtom);
  const [searchFilters, setSearchFilters] = useAtom(searchFiltersAtom);
  const [searchList, setSearchList] = useAtom(searchListAtom);
  const model = new ShopModel();

  const search = async () => {
    setIsLoading(true);
    const results = await model.search(searchFilters).catch((errors) => ({ errors }));
    setIsLoading(false);
    if (results.errors) {
      setErrors(results.errors);
      return;
    }
    setSearchList(results);
  };

  const getEntity = async (entityId) => {
    setIsLoading(true);
    const results = await model.getEntity(entityId).catch((errors) => ({ errors }));
    setIsLoading(false);
    if (results.errors) {
      setErrors(results.errors);
      return;
    }
    setEntity(results);
  };

  const submitEntity = () => {
    var entityToSave = entity;
    if (entityToSave.id) {
      patchEntity(entityToSave);
    } else {
      postEntity(entityToSave);
    }
  };

  const postEntity = async (entityData) => {
    setIsLoading(true);
    const results = await model.postEntity(entityData).catch((errors) => ({ errors }));
    setIsLoading(false);
    if (results.errors) {
      setErrors(results.errors);
      return;
    }
    setEntity(results);
  };
  const patchEntity = async (entityData) => {
    setIsLoading(true);
    const results = await model.patchEntity(entityData).catch((errors) => ({ errors }));
    setIsLoading(false);
    if (results.errors) {
      setErrors(results.errors);
      return;
    }
    setEntity(results);
  };

  const updateFilters = (e) => {
    const { name, value, type, checked } = e.target;
    setSearchFilters({ filters: { ...searchFilters.filters, [name]: value } });
  };
  const updateEntity = (e) => {
    const { name, value, type, checked } = e.target;
    var realValue = type == "checkbox" ? (checked ? true : false) : value;
    setEntity((entity) => ({ ...entity, [name]: realValue }));
  };

  console.log("entity", entity);

  return {
    isLoading,
    setIsLoading,
    entity,
    setEntity,
    errors,
    setErrors,
    searchFilters,
    setSearchFilters,
    searchList,
    setSearchList,
    actions: {
      search,
      updateFilters,
      getEntity,
      updateEntity,
      submitEntity,
    },
  };
};

export default useShopAtoms;
