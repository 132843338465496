const MailSuccess = ({ setForgotPassword }) => {
  return (
    <div>
      <p className=" mb-0 w-75 m-auto text-center">
        Un email vous a été envoyé pour réinitialiser votre mot de passe. Consultez votre boite email et cilquez sur le
        lien qu'il contient.
      </p>
      <button onClick={() => setForgotPassword(false)} className="btn btn-link w-100 mt-3">
        <i className="fa fa-chevron-left me-2"></i>Revenir à la connexion
      </button>
    </div>
  );
};

export default MailSuccess;
