import React, { useState } from "react";
import dataService from "../../../helpers/dataService";

const usePaymentMethods = () => {
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);

  const getPaymentMethods = (customerId) => {
    setIsLoadingPaymentMethods(true);
    dataService.get(
      `payments/methods/${customerId}`,
      setPaymentMethods,
      (err) => {},
      () => setIsLoadingPaymentMethods(false)
    );
  };
  const getPaymentMethod = (paymentMethodId) => {
    setIsLoadingPaymentMethods(true);
    dataService.get(
      `payments/methods/${paymentMethodId}`,
      (data) => setPaymentMethod(data),
      (err) => {},
      () => setIsLoadingPaymentMethods(false)
    );
  };

  return {
    isLoadingPaymentMethods,
    paymentMethods,
    paymentMethod,
    getPaymentMethods,
    getPaymentMethod,
  };
};

export default usePaymentMethods;
