import customerActions from "../../../context/actions/customerActions";
import useStore from "../../../context/useStore";

const useCustomers = () => {
  const [state, dispatch] = useStore();
  const customers_actions = new customerActions({
    state,
    dispatch,
    modelName: "customers",
    entityName: "customer",
    apiUrls: {
      search: "user-customers/search",
      getEntity: "user-customers/:id",
      patchEntity: "user-customers/:id",
      deleteEntity: "user-customers/:id",
      postEntity: "user-customers",
    },
    reducerNames: {
      filters: "UPDATE_CUSTOMERS_FILTERS",
      search: "UPDATE_CUSTOMERS_SEARCH",
      loading: "UPDATE_CUSTOMERS_LOADING",
      list: "UPDATE_CUSTOMERS",
      entity: "UPDATE_CUSTOMER",
      errors: "UPDATE_CUSTOMERS_ERRORS",
    },
  });
  const { updateCustomersFilters, getCustomer, deleteCustomer } = customers_actions;
  const customersState = state.customers;
  const customersFilters = customersState.customersFilters;
  const customersErrors = customersState.errors;
  const { customer, isLoading, customersSearch } = customersState;

  return {
    state,
    dispatch,
    customers_actions,
    customersState,
    customersSearch,
    customersFilters,
    customersErrors,
    customer,
    isLoading,
    deleteCustomer,
    constants: state.constants.items,
  };
};

export default useCustomers;
