import React from "react";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import useManagers from "../../../hooks/useManagers/useManagers";

const ManagersFilters = () => {
  const { updateFilters, managersState, managersFilters, managersErrors, managers_actions } = useManagers();
  return (
    <FiltersContainer
      onSubmit={managers_actions.search}
      filtersState={managersState}
      isDataLoaded={managersState.managersSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={managersFilters.filters.terms}
            change={managers_actions.updateFilters}
            errors={managersErrors}
            inputStyle={{ background: "white" }}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default ManagersFilters;
