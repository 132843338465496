import React, { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import SaveBtn from "../../../common/SaveBtn/SaveBtn";
import useStats from "./useStats";

const EvoAndChart = ({ code, showLine = true, showPie = true, fullWidth }) => {
  const { isLoading, chartState, getDatas, hasDatas, total } = useStats(code);

  useEffect(() => {
    if (!hasDatas) getDatas();
  }, []);

  return (
    <div className="row">
      <div className="col-12 d-flex align-items-center justify-content-end">
        <SaveBtn
          id={`${code}Btn`}
          type={hasDatas ? "btn-default d-center" : "btn-primary d-center"}
          margin="m-0 get-stat-btn"
          save={getDatas}
          isSaving={isLoading}
          text={
            hasDatas ? (
              <>
                <i className="fa fa-sync me-2" />
                Actualiser les données
              </>
            ) : (
              <>
                <i className="fa fa-cloud-download-alt me-2" />
                Charger les données
              </>
            )
          }
        />
      </div>
      {!isLoading && hasDatas ? (
        <>
          <div className={`col-12 ${fullWidth ? "" : "col-md-6"}`}>
            {showLine && (
              <ReactApexChart
                options={chartState.line.options}
                series={chartState.line.series}
                type="bar"
                height={350}
              />
            )}
          </div>

          <div className={`col-12 ${fullWidth ? "" : "col-md-6"}`}>
            {showPie && (
              <ReactApexChart
                options={chartState.pie.options}
                series={chartState.pie.series}
                type="pie"
                height={350}
              />
            )}
          </div>

          <div className="col-12 text-center font-weight-bold">TOTAL : {total}</div>
        </>
      ) : null}
    </div>
  );
};

export default EvoAndChart;
