import React from "react";
import colors from "../../../helpers/colors";
import Loader from "../Loader";

const styles = {
  transition: "0.4s",
};

const SaveBtn = ({
  id = "",
  save,
  isSaving,
  isSaved,
  disabled = false,
  text = "Enregistrer",
  type = "secondary",
  className = "",
  typeBtn = "button",
  loaderColor = colors.primary,
  hideTextOnLoading = true,
}) => {
  return (
    <button
      id={id}
      type={typeBtn}
      disabled={disabled || isSaving}
      className={`d-flex align-items-center btn btn-${isSaving ? "" : type} ${className}`}
      onClick={save}
      style={isSaving ? { ...styles, opacity: ".7", minHeight: 38 } : { ...styles }}
    >
      {isSaving && hideTextOnLoading ? null : (
        <span className={isSaving || isSaved ? "me-2" : ""}>{text}</span>
      )}
      {isSaving && <Loader color={loaderColor} size={15} />}
      {isSaved && !isSaving && <i className="fa fa-check" />}
    </button>
  );
};

export default SaveBtn;
