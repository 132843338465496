import React from "react";
import Control from "../../../common/Controls/Control";
import FiltersContainer from "../../../common/FiltersContainer/FiltersContainer";
import useSubscriptions from "../../../hooks/useSubscriptions/useSubscriptions";

const SubscriptionsFilters = ({ shopId }) => {
  const { subscriptionsState, subscriptionsFilters, subscriptionsErrors, subscriptions_actions } = useSubscriptions({
    shopId,
  });
  return (
    <FiltersContainer
      onSubmit={subscriptions_actions.getList}
      filtersState={subscriptionsState}
      isDataLoaded={subscriptionsState.subscriptionsSearch.data?.length}
    >
      <div className="row">
        <div className="col-12 col-md-4 offset-md-8">
          <Control
            name="terms"
            type="search"
            value={subscriptionsFilters.filters.terms}
            change={subscriptions_actions.updateFilters}
            errors={subscriptionsErrors}
            inputStyle={{ background: "white" }}
          />
        </div>
      </div>
    </FiltersContainer>
  );
};

export default SubscriptionsFilters;
