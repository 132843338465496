import React, { useEffect } from "react";
import Page from "../../../common/layout/Page";
import useChests from "../../../hooks/useChests/useChests";
import useCustomers from "../../../hooks/useCustomers/useCustomers";
import tools from "../../../../helpers/tools";
import ChestCards from "./ChestCards";

const ChestsList = (props) => {
  var { customerId } = props.match.params;
  const { chestsState, chestsSearch, chestsFilters, chests_actions, state, dispatch } = useChests({ customerId });
  const { customersState, customers_actions } = useCustomers();

  useEffect(() => {
    chests_actions.getList();
    customers_actions.getEntity(customerId);
  }, []);

  return (
    <Page errors={chestsState.errors} isLoading={chestsState.isLoading} {...props}>
      <ChestCards />
    </Page>
  );
};

export default ChestsList;
